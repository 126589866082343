// react
import React, {Component} from 'react';

// third-party
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet-async';
import {Link, Redirect, useHistory} from 'react-router-dom';

// application
import Collapse from '../shared/Collapse';
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
import {Check9x7Svg} from '../../svg';

// data stubs
import payments from '../../data/shopPayments';
import theme from '../../data/theme';
import axios from "axios";
import {cartRemoveItem} from "../../store/cart";
// import {CardElement, Elements, ElementsConsumer, useElements, useStripe} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';


//const stripePromise = loadStripe("pk_test_aEGgCX1tjUBzP3Rk3Rgyc9BM");


class ShopPageCheckout extends Component {
    payments = payments;


    constructor(props) {
        super(props);

        //this.history = useHistory();

        //this.handleInputChange = this.handleInputChange().bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            payment: 'bank',
            paymentIntentId: null,
            errors: {},
            email: "",
            emailErrors: "",
            phone: "",
            showDifferentShipping: false,

            billing_address_name: "",
            billing_address_nameErrors: "",
            billing_address_company_name: "",
            billing_address_line_1: "",
            billing_address_line_1Errors: "",
            billing_address_line_2: "",
            billing_address_city: "",
            billing_address_cityErrors: "",
            billing_address_county: "",
            billing_address_countyErrors: "",
            billing_address_zip: "",
            billing_address_zipErrors: "",
            billing_address_country: "United Kingdom",
            billing_address_countryErrors: "",

            shipping_address_name: "",
            shipping_address_company_name: "",
            shipping_address_line_1: "",
            shipping_address_line_2: "",
            shipping_address_city: "",
            shipping_address_county: "",
            shipping_address_zip: "",
            shipping_address_country: "United Kingdom",
        };

        var that = this;

        this.handleChange = this.handleChange.bind(this);


        var payWithCard = function (stripe, card, clientSecret) {
            loading(true);
            stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: card
                }
            })
                .then(function (result) {
                    if (result.error) {

                        // Show error to your customer
                        showError(result.error.message);
                    } else {

                        // The payment succeeded!
                        orderComplete(result.paymentIntent.id);
                    }
                });
        };
        /* ------- UI helpers ------- */
// Shows a success message when the payment is complete
        var orderComplete = function (paymentIntentId) {
            loading(false);
            that.setState({paymentIntentId: paymentIntentId})

            axios.post("/api/catalog/complete_checkout/", that.state).then(function (response) {


                // that.props.cart.items.forEach(function (item) {
                //
                //     cartRemoveItem(item.id);
                // })


                that.props.history.push('/shop/checkout/success/?orderId=' + response.data);
                window.location.reload();
            })
            // document
            //     .querySelector(".result-message a")
            //     .setAttribute(
            //         "href",
            //         "https://dashboard.stripe.com/test/payments/" + paymentIntentId
            //     );
            // document.querySelector(".result-message").classList.remove("hidden");
            // document.querySelector("button").disabled = true;
        };
// Show the customer the error from Stripe if their card fails to charge
        var showError = function (errorMsgText) {
            loading(false);
            var errorMsg = document.querySelector("#card-error");
            errorMsg.textContent = errorMsgText;
            setTimeout(function () {
                errorMsg.textContent = "";
            }, 4000);
        };
// Show a spinner on payment submission
        var loading = function (isLoading) {
            // if (isLoading) {
            //     // Disable the button and show a spinner
            //     document.querySelector("button").disabled = true;
            //     document.querySelector("#spinner").classList.remove("hidden");
            //     document.querySelector("#button-text").classList.add("hidden");
            // } else {
            //     document.querySelector("button").disabled = false;
            //     document.querySelector("#spinner").classList.add("hidden");
            //     document.querySelector("#button-text").classList.remove("hidden");
            // }
        };

        var clientSecret

        var vm = this;
        setTimeout(function () {

            axios.get("/api/catalog/get-default-address/").then(function (response) {
                console.log(response.data);
                that.setState({billing_address_name: response.data.name})
                that.setState({billing_address_company_name: response.data.name})
                that.setState({billing_address_line_1: response.data.line_1})
                that.setState({billing_address_line_2: response.data.line_2})
                that.setState({billing_address_city: response.data.city})
                that.setState({billing_address_county: response.data.county})
                that.setState({billing_address_zip: response.data.postcode})
                that.setState({email: response.data.email})
            })

            axios.post("/api/catalog/get_payment_intent_id/").then(function (response) {
                var stripe = window.Stripe(window.stripePublishable);
                var elements = stripe.elements();
                clientSecret = response.data.client_secret;
                var style = {
                    base: {
                        // Add your base input styles here. For example:
                        fontSize: '16px',
                        color: '#32325d',
                    },
                };

// Create an instance of the card Element.
                var card = elements.create('card', {style: style});

// Add an instance of the card Element into the `card-element` <div>.
                card.mount('#card-element');

                card.on("change", function (event) {
                    // Disable the Pay button if there are no card details in the Element
                    document.querySelector("button").disabled = event.empty;
                    document.querySelector("#card-error").textContent = event.error ? event.error.message : "";
                });

                var form = document.getElementById("payment-form");
                form.addEventListener("submit", function (event) {

                    event.preventDefault();
                    // Complete payment when the submit button is clicked


                    var reset = {};
                    for (var field in that.state) {
                        if (field.endsWith("Errors")) {
                            reset[field] = ""
                        }
                    }
                    that.setState(reset);

                    axios.post("/api/catalog/validate_checkout_information/", that.state).then(function (response) {


                        payWithCard(stripe, card, clientSecret);
                    }).catch(function (err) {


                        var errors = {};
                        for (var errorField in err.response.data) {
                            var field = errorField + "Errors";
                            errors[field] = err.response.data[errorField];
                        }
                        that.setState(errors);
                        that.setState({"errors": err.response.data});
                    })
                });
            });
        }, 500);

    }


    // handlePaymentChange = (event) => {
    //     if (event.target.checked) {
    //         this.setState({payment: event.target.value});
    //     }
    // };

    // handleInputChange(event) {
    //     const target = event.target;
    //     this.setState({
    //         [target.name]: target.value
    //     });
    // }

    renderTotals() {
        const {cart} = this.props;

        if (cart.extraLines.length <= 0) {
            return null;
        }

        const extraLines = cart.extraLines.map((extraLine, index) => (
            <tr key={index}>
                <th>{extraLine.title}</th>
                <td><Currency value={extraLine.price}/></td>
            </tr>
        ));

        return (
            <React.Fragment>
                <tbody className="checkout__totals-subtotals">
                <tr>
                    <th>Subtotal</th>
                    <td><Currency value={cart.subtotal}/></td>
                </tr>
                {extraLines}
                </tbody>
            </React.Fragment>
        );
    }

    renderCart() {
        const {cart} = this.props;

        const items = cart.items.map((item) => (
            <tr key={item.id}>
                <td>{`${item.product.name} × ${item.quantity}`}</td>
                <td><Currency value={item.total}/></td>
            </tr>
        ));

        return (
            <table className="checkout__totals">
                <thead className="checkout__totals-header">
                <tr>
                    <th>Product</th>
                    <th>Total</th>
                </tr>
                </thead>
                <tbody className="checkout__totals-products">
                {items}
                </tbody>
                {this.renderTotals()}
                <tfoot className="checkout__totals-footer">
                <tr>
                    <th>Total</th>
                    <td><Currency value={cart.total}/></td>
                </tr>
                </tfoot>
            </table>
        );
    }

    checkout() {
        // axios.post("/api/checkout/", {
        //     "transaction_id": "...",
        //     "shipping_rate": 0.0,
        //     "email": "john@smith.com",
        //     "address": {
        //         "billing_name": "john smith",
        //         "billing_address_line_1": "...",
        //         "billing_address_line_2": "...",
        //         "billing_address_city": "",
        //         "billing_address_zip": "",
        //         "billing_address_country": "",
        //         "shipping_name": "john smith",
        //         "shipping_address_line_1": "...",
        //         "shipping_address_line_2": "...",
        //         "shipping_address_city": "",
        //         "shipping_address_zip": "",
        //         "shipping_address_country": "",
        //     }
        // }).then(function (response) {
        //
        // })
    }

    showTestCard() {
        return window.debug;
    }

    renderStripeJs() {

        var ShowTestCard = function () {
            if (window.debug) {
                return <div>
                    Test card number: 4242 4242 4242 4242 (use any date in future, any other details)
                </div>;
            } else {
                return <div></div>
            }
        }

        return (
            <div>

                <h3 className="card-title">Payment details</h3>
                <ShowTestCard/>
                <form action="/charge" method="post" id="payment-form">
                    <div className="form-row">
                        <div id="card-element"
                             style={{width: "100%", border: "1px solid #ced4da", padding: "10px"}}></div>
                    </div>

                    <br/>
                    <div className="checkout__agree form-group">
                        <div className="form-check">
                        <span className="form-check-input input-check">
                        <span className="input-check__body">
                        <input className="input-check__input" type="checkbox"
                               id="checkout-terms"/>
                        <span className="input-check__box"/>
                        <Check9x7Svg className="input-check__icon"/>
                        </span>
                        </span>
                            <label className="form-check-label" htmlFor="checkout-terms">
                                I have read and agree to the website
                                <Link to="site/terms"> terms and conditions</Link>
                                *
                            </label>
                        </div>
                    </div>

                    {/*<button type="submit" className="btn btn-primary btn-xl btn-block"*/}
                    {/*        onClick={this.checkout}>Place Order*/}
                    {/*</button>*/}

                    <p id="card-error" role="alert"></p>

                    {/*<p className="result-message hidden">*/}
                    {/*    Payment succeeded, see the result in your*/}
                    {/*    <a href="" target="_blank">Stripe dashboard.</a> Refresh the page to pay again.*/}
                    {/*</p>*/}

                    <button id="submit" className="btn btn-primary btn-xl btn-block">
                        <div className="spinner hidden" id="spinner"></div>
                        <span id="button-text">Place Order</span>
                    </button>
                    <br/>
                    <div>
                        {
                            Object.entries(this.state.errors)
                                .map(([key, value]) => <div class="formErrorKipper">{value}</div>)
                        }
                    </div>

                </form>
                <br/>
            </div>
        );
    }

    renderPaymentsList() {
        const {
            payment: currentPayment
        }

            = this.state;

        const payments = this.payments.map((payment) => {
                const renderPayment = ({setItemRef, setContentRef}) => (
                        <
                            li
                            className="payment-methods__item"
                            ref={setItemRef}>
                            < label
                                className="payment-methods__item-header">
    < span
        className="payment-methods__item-radio input-radio">
    < span
        className="input-radio__body">
    < input
        type="radio"
        className="input-radio__input"
        name="checkout_payment_method"
        value={payment.key}
        checked={currentPayment === payment.key
        }
        onChange={this.handlePaymentChange}
    />
<span className="input-radio__circle"/>
</span>
</span>
                                <span className="payment-methods__item-title">{payment.title}</span>
                            </label>
                            <div className="payment-methods__item-container" ref={setContentRef}>
                                <div className="payment-methods__item-description text-muted">{payment.description}</div>
                            </div>
                        </li>
                    )
                ;

                return (
                    <Collapse
                        key={payment.key}
                        open={currentPayment === payment.key}
                        toggleClass="payment-methods__item--active"
                        render={renderPayment}
                    />
                );
            })
        ;

        return (
            <div className="payment-methods">
                <ul className="payment-methods__list">
                    {payments}
                </ul>
            </div>
        );
    }


    handleChange(evt) {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            ...this.state,
            [evt.target.name]: value
        });
    }

    render() {
        const {cart} = this.props;

        if (cart.items.length < 1) {
            return <Redirect to="cart"/>;
        }

        const breadcrumb = [
            {title: 'Home', url: ''},
            {title: 'Shopping Cart', url: '/shop/cart'},
            {title: 'Checkout', url: ''},
        ];

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Checkout — ${theme.name}`}</title>
                </Helmet>

                <PageHeader header="Checkout" breadcrumb={breadcrumb}/>

                <div className="checkout block">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mb-3">
                                {/*<div className="alert alert-primary alert-lg">*/}
                                {/*    Returning customer?*/}
                                {/*    {' '}*/}
                                {/*    <Link to="/account/login">Click here to login</Link>*/}
                                {/*</div>*/}
                            </div>

                            <div className="col-12 col-lg-6 col-xl-7">
                                <div className="card mb-lg-0">
                                    <div className="card-body">
                                        <h3 className="card-title">Billing details</h3>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label htmlFor="checkout-first-name">Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-first-name"
                                                    placeholder="Name"
                                                    name="billing_address_name"
                                                    value={this.state.billing_address_name}
                                                    onChange={this.handleChange}
                                                />
                                                <div
                                                    className="formErrorKipper">{this.state.billing_address_nameErrors}</div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="checkout-company-name">
                                                Company Name
                                                {' '}
                                                <span className="text-muted">(Optional)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="checkout-company-name"
                                                placeholder="Company Name"
                                                name="billing_address_company_name"
                                                value={this.state.billing_address_company_name}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-country">Country</label>
                                            <select id="checkout-country" className="form-control"
                                                    name="billing_address_country"
                                                    value={this.state.billing_address_country}
                                                    onChange={this.handleChange}
                                            >
                                                <option>Select a country...</option>
                                                <option>United Kingdom</option>
                                                {/*<option>United States</option>*/}
                                                {/*<option>Russia</option>*/}
                                                {/*<option>Italy</option>*/}
                                                {/*<option>France</option>*/}
                                                {/*<option>Ukraine</option>*/}
                                                {/*<option>Germany</option>*/}
                                                {/*<option>Australia</option>*/}
                                            </select>
                                            <div
                                                className="formErrorKipper">{this.state.billing_address_countryErrors}</div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-street-address">Street Address</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="checkout-street-address"
                                                placeholder="Street Address"
                                                name="billing_address_line_1"
                                                value={this.state.billing_address_line_1}
                                                onChange={this.handleChange}
                                            />
                                            <div
                                                className="formErrorKipper">{this.state.billing_address_line_1Errors}</div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-address">
                                                Apartment, suite, unit etc.
                                                {' '}
                                                <span className="text-muted">(Optional)</span>
                                            </label>
                                            <input type="text" className="form-control" id="checkout-address"
                                                   name="billing_address_line_2"
                                                   value={this.state.billing_address_line_2}
                                                   onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-city">Town / City</label>
                                            <input type="text" className="form-control" id="checkout-city"
                                                   name="billing_address_city"
                                                   value={this.state.billing_address_city}
                                                   onChange={this.handleChange}
                                            />
                                            <div
                                                className="formErrorKipper">{this.state.billing_address_cityErrors}</div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-state">County</label>
                                            <input type="text" className="form-control" id="checkout-state"
                                                   name="billing_address_county"
                                                   value={this.state.billing_address_county}
                                                   onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-postcode">Postcode</label>
                                            <input type="text" className="form-control" id="checkout-postcode"
                                                   name="billing_address_zip"
                                                   value={this.state.billing_address_zip}
                                                   onChange={this.handleChange}
                                            />
                                            <div
                                                className="formErrorKipper">{this.state.billing_address_zipErrors}</div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-email">Email address</label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={this.state.email}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                    id="checkout-email"
                                                    placeholder="Email address"
                                                />
                                                <div className="formErrorKipper">{this.state.emailErrors}</div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-phone">Phone</label>
                                                <input type="text"
                                                       name="phone"
                                                       value={this.state.phone}
                                                       onChange={this.handleChange}
                                                       className="form-control" id="checkout-phone"
                                                       placeholder="Phone"/>
                                            </div>
                                        </div>

                                        {/*<div className="form-group">*/}
                                        {/*    <div className="form-check">*/}
                                        {/*                <span className="form-check-input input-check">*/}
                                        {/*                <span className="input-check__body">*/}
                                        {/*                <input className="input-check__input" type="checkbox"*/}
                                        {/*                       id="checkout-create-account"/>*/}
                                        {/*                    <span className="input-check__box"/>*/}
                                        {/*                    <Check9x7Svg className="input-check__icon"/>*/}
                                        {/*                    </span>*/}
                                        {/*                    </span>*/}
                                        {/*        <label className="form-check-label"*/}
                                        {/*               htmlFor="checkout-create-account">*/}
                                        {/*            Create an account?*/}
                                        {/*        </label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="card-divider"/>
                                    <div className="card-body">
                                        <h3 className="card-title">Delivery Details</h3>

                                        <div className="form-group">
                                            <div className="form-check">
<span className="form-check-input input-check">
<span className="input-check__body">
<input
    className="input-check__input"
    type="checkbox"
    name="showDifferentShipping"
    checked={this.state.showDifferentShipping}
    onChange={this.handleChange}
/>
<span className="input-check__box"/>
<Check9x7Svg className="input-check__icon"/>
</span>
</span>
                                                <label className="form-check-label"
                                                       htmlFor="checkout-different-address">
                                                    Deliver to a different address?
                                                </label>
                                            </div>
                                        </div>
                                        {this.state.showDifferentShipping &&
                                            <div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label htmlFor="checkout-first-name">Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="checkout-first-name"
                                                            placeholder="Name"
                                                            name="shipping_address_name"
                                                            value={this.state.shipping_address_name}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="checkout-company-name">
                                                        Company Name
                                                        {' '}
                                                        <span className="text-muted">(Optional)</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="checkout-company-name"
                                                        placeholder="Company Name"
                                                        name="shipping_address_company_name"
                                                        value={this.state.shipping_address_company_name}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="checkout-country">Country</label>
                                                    <select id="checkout-country" className="form-control"
                                                            name="shipping_address_country"
                                                            value={this.state.shipping_address_country}
                                                            onChange={this.handleChange}
                                                    >
                                                        <option>Select a country...</option>
                                                        <option selected>United Kingdom</option>
                                                        {/*<option>United States</option>*/}
                                                        {/*<option>Russia</option>*/}
                                                        {/*<option>Italy</option>*/}
                                                        {/*<option>France</option>*/}
                                                        {/*<option>Ukraine</option>*/}
                                                        {/*<option>Germany</option>*/}
                                                        {/*<option>Australia</option>*/}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="checkout-street-address">Street Address</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="checkout-street-address"
                                                        placeholder="Street Address"
                                                        name="shipping_address_line_1"
                                                        value={this.state.shipping_address_line_1}
                                                        onChange={this.handleChange}

                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="checkout-address">
                                                        Apartment, suite, unit etc.
                                                        {' '}
                                                        <span className="text-muted">(Optional)</span>
                                                    </label>
                                                    <input type="text" className="form-control" id="checkout-address"
                                                           name="shipping_address_line_2"
                                                           value={this.state.shipping_address_line_2}
                                                           onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="checkout-city">Town / City</label>
                                                    <input type="text" className="form-control" id="checkout-city"
                                                           name="shipping_address_city"
                                                           value={this.state.shipping_address_city}
                                                           onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="checkout-state">County</label>
                                                    <input type="text" className="form-control" id="checkout-state"
                                                           name="shipping_address_county"
                                                           value={this.state.shipping_address_county}
                                                           onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="checkout-postcode">Postcode</label>
                                                    <input type="text" className="form-control" id="checkout-postcode"
                                                           name="shipping_address_zip"
                                                           value={this.state.shipping_address_zip}
                                                           onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        }

                                        <div className="form-group">
                                            <label htmlFor="checkout-comment">
                                                Order notes
                                                {' '}
                                                <span className="text-muted">(Optional)</span>
                                            </label>
                                            <textarea id="checkout-comment" className="form-control" rows="4"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <h3 className="card-title">Your Order</h3>

                                        {this.renderCart()}

                                        {/*{this.renderPaymentsList()}*/}

                                        {this.renderStripeJs()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const
    mapStateToProps = (state) => (
        {
            cart: state.cart,
        }
    );

const
    mapDispatchToProps =
        {}
;

export default connect(mapStateToProps, mapDispatchToProps)

(
    ShopPageCheckout
)
;
