// react
import React from 'react';

// third-party
import {Helmet} from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';
import {Link} from "react-router-dom";

function SitePageReturns() {
    const breadcrumb = [
        {title: 'Home', url: ''},
        {title: 'Returns', url: ''},
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Returns — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Returns" breadcrumb={breadcrumb}/>


            <div className="block faq">
                <div className="container">
                      <h5>
                                            Thanks for shopping at bookingstock.co.uk.

If you are not entirely satisfied with your purchase, we're here to help.
</h5>
                    <p>
                                If you have any questions on how to return your item to us, contact us.
                                <Link to="/site/contact-us"> contact page.</Link>
                            </p>
                    <div className="faq__section">
                        <div className="faq__section-title">
                        </div>
                        <div className="faq__section-body">
                            <div className="row">
                                <div className="faq__section-column col-12 col-lg-12">
                                    <div className="typography">
                                        <h4>Returns</h4>

                                        <p>If you would like to return a product please <Link to="/site/contact-us">contact us</Link> within 14 days of purchase with reference to your order number and the items for return as well as your reason for return. We will review your request and notify you if your return has been accepted.</p>

<p>You have 7 calendar days to return an item once the acceptance received.</p>

<p>Your item must be unused and in the same condition that you received it.</p>

<p>Your item must be in the original packaging.</p>

<p>Your item needs to have the receipt or proof of purchase.</p>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="faq__section">
                        <div className="faq__section-title">
                        </div>
                        <div className="faq__section-body">
                            <div className="row">
                                <div className="faq__section-column col-12 col-lg-12">
                                    <div className="typography">
                                        <h4>Refunds</h4>

                                        <p>

We will notify you when we recieve your return, after we have inspected it, if approved we will refund you via your original payment method.
</p>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="faq__section">
                        <div className="faq__section-title">
                        </div>
                        <div className="faq__section-body">
                            <div className="row">
                                <div className="faq__section-column col-12 col-lg-12">
                                    <div className="typography">
                                        <h4>Shipping</h4>

                                        <p>Shipping costs are non¬refundable.</p>
                                        <p>You will be responsible organising return shipping, and paying for any costs of returning your item.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
<div className="faq__section">
                        <div className="faq__section-title">
                        </div>
                        <div className="faq__section-body">
                            <div className="row">
                                <div className="faq__section-column col-12 col-lg-12">
                                    <div className="typography">
                                        <h4>Contact us</h4>
 <p>
                                For information about how to contact us, please visit our <Link to="/site/contact-us">contact page</Link>
                                .
                            </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*REDMAN <div className="faq__section">*/}
                    {/*    <div className="faq__section-title">*/}
                    {/*        <h3>Orders and Returns</h3>*/}
                    {/*    </div>*/}
                    {/*    <div className="faq__section-body">*/}
                    {/*        <div className="row">*/}
                    {/*            <div className="faq__section-column col-12 col-lg-6">*/}
                    {/*                <div className="typography">*/}
                    {/*                    <h6>What shipping methods are available?</h6>*/}

                    {/*                    <p>*/}
                    {/*                        Lorem ipsum dolor sit amet conse ctetur adipisicing*/}
                    {/*                        elit, sed do eiusmod tempor incididunt ut labore et*/}
                    {/*                        dolore magna aliqua. Ut enim ad minim veniam, quis*/}
                    {/*                        nostrud exercitation ullamco laboris nisi ut aliquip*/}
                    {/*                        ex ea commodo consequat. Duis aute irure dolor in*/}
                    {/*                        reprehenderit in voluptate velit esse cillum dolore*/}
                    {/*                        eu fugiat.*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="faq__section-column col-12 col-lg-6">*/}
                    {/*                <div className="typography">*/}
                    {/*                    <h6>What shipping methods are available?</h6>*/}

                    {/*                    <p>*/}
                    {/*                        Lorem ipsum dolor sit amet conse ctetur adipisicing*/}
                    {/*                        elit, sed do eiusmod tempor incididunt ut labore et*/}
                    {/*                        dolore magna aliqua. Ut enim ad minim veniam, quis*/}
                    {/*                        nostrud exercitation ullamco laboris nisi ut aliquip*/}
                    {/*                        ex ea commodo consequat. Duis aute irure dolor in*/}
                    {/*                        reprehenderit in voluptate velit esse cillum dolore*/}
                    {/*                        eu fugiat.*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageReturns;
