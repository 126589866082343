// react
import React from 'react';

// data stubs
import specification from '../../data/shopProductSpec';
import InputNumber from "../shared/InputNumber";
import AsyncAction from "../shared/AsyncAction";
import classNames from "classnames";
import {cartAddItem} from "../../store/cart";
import ProductVariantBuyRow from "./ProductVariantBuyRow";
import ProductVariantBuyCard from "./ProductVariantBuyCard";


function ProductTabShop(props) {

    const variants = props.product.variants_by_price.map((variant, index) => {
        if (props.desktop) {
            return (
                <ProductVariantBuyRow key={index} product={props.product} variant={variant} desktop={props.desktop}/>
            );
        } else {
            return (
                <ProductVariantBuyCard key={index} product={props.product} variant={variant} desktop={props.desktop}/>
            );
        }

    });

    return (
        <div className="spec">
            <h3 className="spec__header">{props.product.name}</h3>
            {/*REDMAN - removed "shop" from above header*/}
            {props.desktop &&
                <table style={{width: "100%"}}>
                    <thead>
                    <tr className="shop-table-header">
                        <th scope="col">Product</th>
                        <th scope="col">Pack</th>
                        <th scope="col">Delivery time</th>
                        <th scope="col" className="tableEnd">Prices from</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {variants}
                    </tbody>
                </table>
            }

            {!props.desktop &&
                <div>
                    {variants}
                </div>
            }


            <div className="spec__disclaimer">
                Information on technical characteristics, the delivery set, the country of
                manufacture and the appearance of the goods is for reference only and is based on
                the latest information available at the time of publication.
            </div>
        </div>
    );
}

export default ProductTabShop;
