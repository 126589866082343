export default [
    {
        title: 'Home',
        url: '',
        // submenu: {
        //     type: 'menu',
        //     menu: [
        //         {title: 'Home 1', url: '/'},
        //         {title: 'Home 2', url: '/home-two'},
        //         {title: 'Home 3', url: '/home-three'},
        //     ],
        // },
    },
    // {
    //     title: 'Products',
    //     url: '/shop/catalog/',
    //     // submenu: {
    //     //     type: 'menu',
    //     //     menu: [
    //     //         {title: 'Login', url: '/account/login'},
    //     //         {title: 'Dashboard', url: '/account/dashboard'},
    //     //         {title: 'Edit Profile', url: '/account/profile'},
    //     //     ],
    //     // },
    // },
    {
        title: 'About',
        url: '/site/about-us/',
        // submenu: {
        //     type: 'menu',
        //     menu: [
        //         {title: 'Login', url: '/account/login'},
        //         {title: 'Dashboard', url: '/account/dashboard'},
        //         {title: 'Edit Profile', url: '/account/profile'},
        //     ],
        // },
    },
    {
        title: 'Contact',
        url: '/site/contact-us/',
        // submenu: {
        //     type: 'menu',
        //     menu: [
        //         {title: 'Login', url: '/account/login'},
        //         {title: 'Dashboard', url: '/account/dashboard'},
        //         {title: 'Edit Profile', url: '/account/profile'},
        //     ],
        // },
    },
];
