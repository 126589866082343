// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';

// application
import {
    ShippingSvg,
    FactorySvg,
    BulkSvg,
    PricingSvg,
} from '../../svg';

export default function BlockFeaturesOld(props) {
    const { layout } = props;

    return (
        <div className={`block block-features block-features--layout--${layout}`}>
            <div className="container">
                <div className="block-features__list">
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <ShippingSvg />
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Fast delivery</div>
                            {/*<div className="block-features__subtitle">Within 5 working days</div>*/}
                        </div>
                    </div>
                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <FactorySvg />
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Factory direct products</div>
                            {/*<div className="block-features__subtitle">Contact us anytime</div>*/}
                        </div>
                    </div>
                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <PricingSvg/>
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Competitive pricing</div>
                            {/*<div className="block-features__subtitle">Powered by Stripe</div>*/}
                        </div>
                    </div>
                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <BulkSvg />
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Bulk discounts</div>
                            {/*<div className="block-features__subtitle">Contact us for great deals</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

BlockFeaturesOld.propTypes = {
    layout: PropTypes.oneOf(['classic', 'boxed']),
};

BlockFeaturesOld.defaultProps = {
    layout: 'classic',
};
