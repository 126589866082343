import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import theme from "../../data/theme";
import PageHeader from "../shared/PageHeader";
import {Link} from "react-router-dom";
import userApi from "../../api/user";
import classNames from "classnames";


export default function AccountPageResetPassword() {
    const breadcrumb = [
        {title: 'Home', url: ''},
        {title: 'My Account', url: ''},
    ];

    const [email, setEmail] = useState();
    const [emailSent, setEmailSent] = useState(false);
    const [errors, setErrors] = useState({});
    const handleSubmit = async e => {
        e.preventDefault();
        userApi.passwordReset(email)
            .then(function (response) {
                setEmailSent(true)
            })
            .catch(function (error) {

                if (error.response.data) {
                    setErrors(error.response.data);
                }
            });
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Reset Password — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="My Account" breadcrumb={breadcrumb}/>

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <h3 className="card-title">Reset your password</h3>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="login-email">Email address</label>
                                            <input
                                                id="login-email"
                                                type="email"
                                                className={classNames("form-control", {"is-invalid": errors?.email && errors.email.length > 0})}
                                                placeholder="Enter email"
                                                onChange={e => setEmail(e.target.value)}
                                            />
                                            {errors?.email && errors.email.map(function (x, idx) {
                                                return (<div className={"invalid-feedback"} key={idx}>{x}</div>)
                                            })
                                            }
                                            { emailSent &&
                                                <div>If email exists, reset email has been send</div>
                                            }
                                            <small className="form-text text-muted">
                                                <Link to="/account/login">Return to login</Link>
                                            </small>
                                        </div>
                                        <button type="submit" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                            Reset Password
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
