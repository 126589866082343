// third-party
import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';

// reducer
import rootReducer from './rootReducer';
import version from './version';
import {cartLoadAll} from "./cart";

function load() {
    let state;

    try {
        state = localStorage.getItem('state');

        if (typeof state === 'string') {
            state = JSON.parse(state);
        }

        if (state && state.version !== version) {
            state = undefined;
        }

    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    //Got rid of this, so the return above is the one that works?
    //alert(state.cart.quantity);
    return state || undefined;
}

const store = createStore(rootReducer, load(), compose(
    applyMiddleware(thunk),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
));

function save() {
    try {
        localStorage.setItem('state', JSON.stringify(store.getState()));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
}

store.subscribe(() => save());

fetch('/api/catalog/get-basket/').then(function (response) {
    response.json().then(function (data) {
        cartLoadAll(data)(store.dispatch);
        //thing();
        // state.cart = data;
        //
        // save();
        // return state || undefined;

    });
});

window.store = store;


export default store;
