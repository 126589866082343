export default {
    name: 'Bookingstock.co.uk',
    fullName: 'Bookingstock.co.uk - Accessible low cost manufacturing',
    url: 'https://themeforest.net/item/stroyka-tools-store-react-ecommerce-template/23909258',
    author: {
        name: 'Kos',
        profile_url: 'https://themeforest.net/user/kos9',
    },
    contacts: {
        address: 'Registered in England, Registered Office 85 Great Portland Street, First Floor, London W1W 7LT Reg. Number 13422501',
        email: 'info@bookingstock.co.uk',
        phone: '(800) 060-0730',
    },
};
