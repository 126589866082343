// react
import React, {useState} from 'react';

// third-party
import classNames from 'classnames';
import {
    Link,
    matchPath,
    Redirect,
    Switch,
    Route, useHistory,
} from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';

// pages
import AccountPageAddresses from './AccountPageAddresses';
import AccountPageDashboard from './AccountPageDashboard';
import AccountPageEditAddress from './AccountPageEditAddress';
import AccountPageOrderDetails from './AccountPageOrderDetails';
import AccountPageOrders from './AccountPageOrders';
import AccountPagePassword from './AccountPagePassword';
import AccountPageProfile from './AccountPageProfile';

export default function AccountLayout(props) {
    const {match, location} = props;
    const [isLoggedIn, setIsLoggedIn] = useState(window.userIsLoggedIn);
    let history = useHistory();

    const breadcrumb = [
        {title: 'Home', url: ''},
        {title: 'My Account', url: ''},
    ];

    const links = [
        {title: 'Recent Orders', url: 'dashboard'},
        //{title: 'Edit Profile', url: 'profile'},
        // {title: 'Order History', url: 'orders'},
        // {title: 'Order Details', url: 'orders/5'},
        {title: 'Addresses', url: 'addresses'},
        //{title: 'Edit Address', url: 'addresses/5'},
        {title: 'Password', url: 'password'},
    ].map((link) => {
        const url = `${match.url}/${link.url}`;
        const isActive = matchPath(location.pathname, {path: url, exact: true});
        const classes = classNames('account-nav__item', {
            'account-nav__item--active': isActive,
        });

        return (
            <li key={link.url} className={classes}>
                <Link to={url}>{link.title}</Link>
            </li>
        );
    });

    function signOut(e) {
        e.preventDefault();
        //TODO: change to axios
        return fetch('/api/users/account/signout/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then(function (response) {
            history.push('/')
        })
    }

    return (
        <React.Fragment>
            <PageHeader header="My Account" breadcrumb={breadcrumb}/>

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-3 d-flex">
                            <div className="account-nav flex-grow-1">
                                <h4 className="account-nav__title">Navigation</h4>
                                <ul>
                                    {links}
                                    <li className="account-nav__item">
                                        {isLoggedIn ?
                                            <a href="/embed/accounts/logout/">Sign out</a>
                                            :
                                            <link to="/account/login">Sign in</link>
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                            <Switch>
                                <Redirect exact from={match.path} to={`${match.path}/dashboard`}/>
                                <Route exact path={`${match.path}/dashboard`} component={AccountPageDashboard}/>
                                <Route exact path={`${match.path}/profile`} component={AccountPageProfile}/>
                                <Route exact path={`${match.path}/orders`} component={AccountPageOrders}/>
                                <Route exact path={`${match.path}/orders/:orderId`}
                                       component={AccountPageOrderDetails}/>
                                <Route exact path={`${match.path}/addresses`} component={AccountPageAddresses}/>
                                <Route exact path={`${match.path}/addresses/:addressId`}
                                       component={AccountPageEditAddress}/>
                                <Route exact path={`${match.path}/password`} component={AccountPagePassword}/>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
