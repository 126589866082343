// react
import React, {useState} from 'react';

// third-party
import {Link, useHistory} from 'react-router-dom';

// application
import Indicator from './Indicator';
import {Person20Svg} from '../../svg';
import userApi from "../../api/user";
import classNames from "classnames";
import authService from "../../services/auth";

export default function IndicatorAccount() {
    const [email, setEmail] = useState(window.userEmail);
    const [password, setPassword] = useState();
    const [loginErrors, setloginErrors] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(window.userIsLoggedIn);
    let history = useHistory();

    const handleSignIn = async e => {
        e.preventDefault();
        userApi.signIn(email, password)
            .then(function (response) {
                window.location.href = '/account/dashboard';
            })
            .catch(function (error) {

                if (error.response.data) {
                    setloginErrors(error.response.data);
                }
            });
    };

    //this.isLoggedIn = false;

    const dropdown = (
        <div className="account-menu">
            {!isLoggedIn &&
                <form className="account-menu__form" onSubmit={handleSignIn}>
                    <div className="account-menu__form-title">Log In to Your Account</div>
                    <div className="form-group">
                        <label htmlFor="header-signin-email" className="sr-only">Email address</label>
                        <input
                            id="header-signin-email"
                            type="email"
                            className={classNames("form-control", "form-control-sm", {"is-invalid": loginErrors.email && loginErrors.email.length > 0})}
                            placeholder="Email address"
                            onChange={e => setEmail(e.target.value)}
                        />
                        {loginErrors.email && loginErrors.email.map(function (x, idx) {
                            return (<div className={"invalid-feedback"} key={idx}>{x}</div>)
                        })
                        }
                    </div>
                    <div className="form-group">
                        <label htmlFor="header-signin-password" className="sr-only">Password</label>
                        <div className="account-menu__form-forgot">
                            <input
                                id="header-signin-password"
                                type="password"
                                className={classNames("form-control", "form-control-sm", {"is-invalid": loginErrors.password && loginErrors.password.length > 0})}
                                placeholder="Password"
                                onChange={e => setPassword(e.target.value)}
                            />
                            <Link to="/account/reset" className="account-menu__form-forgot-link">Forgot?</Link>
                            {loginErrors.password && loginErrors.password.map(function (x, idx) {
                                return (<div className={"invalid-feedback"} key={idx}>{x}</div>)
                            })
                            }
                        </div>

                    </div>
                    {loginErrors.non_field_errors && loginErrors.non_field_errors.map(function (x, idx) {
                        return (<div style={{color: 'red'}} key={idx}>{x}</div>)
                    })
                    }
                    <div className="form-group account-menu__form-button">
                        <button type="submit" className="btn btn-primary btn-sm">Login</button>
                    </div>
                    <div className="account-menu__form-link">
                        <Link to="/account/login">Create An Account</Link>
                    </div>
                </form>
            }
            {isLoggedIn &&
                <>
                    <div className="account-menu__divider"/>
                    <Link to="/account/dashboard" className="account-menu__user">
                        {/*<div className="account-menu__user-avatar">*/}
                        {/*    <img src="images/avatars/avatar-3.jpg" alt=""/>*/}
                        {/*</div>*/}
                        <div className="account-menu__user-info">
                            {/*<div className="account-menu__user-name">Helena Garcia</div>*/}
                            <div className="account-menu__user-email">{email}</div>
                        </div>
                    </Link>
                    <div className="account-menu__divider"/>
                    <ul className="account-menu__links">
                        {/*<li><Link to="/account/profile">Edit Profile</Link></li>*/}
                        {/*<li><Link to="/account/orders">Order History</Link></li>*/}
                        {/*<li><Link to="/account/addresses">Addresses</Link></li>*/}
                        <li><Link to="/account/dashboard">My Account</Link></li>
                        <li><Link to="/account/password">Change Password</Link></li>
                    </ul>
                    <div className="account-menu__divider"/>
                    <ul className="account-menu__links">
                        <li><a href="/embed/accounts/logout/">Logout</a></li>
                    </ul>
                </>
            }
        </div>
    );

    return (

        <Indicator url="/account" dropdown={dropdown} icon={<Person20Svg/>}/>
    );
}
