// react
import React, {useState} from 'react';

// third-party
import {Helmet} from 'react-helmet-async';

// data stubs
import theme from '../../data/theme';
import userApi from "../../api/user";

export default function AccountPagePassword() {

    const [password1, setPassword1] = useState();
    const [password2, setPassword2] = useState();
    const [errors, setErrors] = useState([]);
    const [errorsPassword1, setErrorsPassword1] = useState([]);
    const [errorsPassword2, setErrorsPassword2] = useState([]);
    const [emailSent, setEmailSent] = useState(false);
    const handleSubmit = async e => {
        e.preventDefault();
        setErrorsPassword1([]);
        setErrorsPassword2([]);
        setErrors([]);
        userApi.passwordChange(password1)
            .then(function (response) {
                setEmailSent(true);
            })
            .catch(function (error) {
                console.log(error.response);
                if (typeof (error.response.data.new_password1) != "undefined") {
                    console.log(1)

                    setErrorsPassword1(error.response.data.new_password1);
                }
                if (typeof (error.response.data.new_password2) != "undefined") {
                    console.log(2)
                    setErrorsPassword2(error.response.data.new_password2);
                }
                if (error.response.data) {
                    console.log(3)
                    setErrors(error.response.data.new_password1);
                }

                console.log(errors, errorsPassword1, errorsPassword2)
            });
    };


    return (
        <div className="card">
            <Helmet>
                <title>{`Change Password — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>Change Password</h5>
            </div>
            <div className="card-divider"/>
            <form onSubmit={handleSubmit}>
                <div className="card-body">
                    <div className="row no-gutters">
                        <div className="col-12 col-lg-7 col-xl-6">
                            {/*<div className="form-group">*/}
                            {/*    <label htmlFor="password-current">Current Password</label>*/}
                            {/*    <input*/}
                            {/*        type="password"*/}
                            {/*        className="form-control"*/}
                            {/*        id="password-current"*/}
                            {/*        placeholder="Current Password"*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <div className="form-group">
                                <label htmlFor="password-new">New Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password-new"
                                    placeholder="New Password"
                                    onChange={e => setPassword1(e.target.value)}
                                />
                            </div>
                            {errorsPassword1}
                            <br/>
                            <br/>
                            {errorsPassword1 && errorsPassword1.map(function (x, idx) {
                                return (<div className={"invalid-feedback"} key={idx}>{x}</div>)
                            })}
                            <div className="form-group">
                                <label htmlFor="password-confirm">Reenter New Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password-confirm"
                                    placeholder="Reenter New Password"
                                    onChange={e => setPassword2(e.target.value)}
                                />
                            </div>

                            {errorsPassword2}
                            <br/>
                            <br/>
                            {errorsPassword2 && errorsPassword2.map(function (x, idx) {
                                return (<div className={"invalid-feedback"} key={idx}>{x}</div>)
                            })}
                            {errors.map(function (x, idx) {
                                return (<div className={"invalid-feedback"} key={idx}>{x}</div>)
                            })}
                            {emailSent &&
                                <div>Password changed</div>
                            }

                            <div className="form-group mt-5 mb-0">
                                <button type="submit" className="btn btn-primary">Change</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
