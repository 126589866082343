// react
import React, {useState} from 'react';

// third-party
import {Helmet} from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';

// blocks
import BlockMap from '../blocks/BlockMap';

// data stubs
import theme from '../../data/theme';
import axios from "axios";

function SitePageContactUs() {
    const breadcrumb = [
        {title: 'Home', url: ''},
        {title: 'Contact Us', url: ''},
    ];

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({});
    const [showThanks, setShowThanks] = useState(false);


    var submitForm = function (e) {
        e.preventDefault()
        //
        //var formData = new FormData(document.querySelector('form'));
        //

        let formData = new FormData();    //formdata object

        formData.append('name', name);   //append the values with key, value pair
        formData.append('email', email);
        formData.append('subject', subject);
        formData.append('message', message);

        const config = {
            headers: {'content-type': 'multipart/form-data'}
        }

        axios.post("/api/contact-send-email/", formData, config)
            .then(response => {


                setShowThanks(true);
            })
            .catch(error => {


                if (error.response) {
                    setErrors(error.response.data.errors);

                }
            });
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Contact Us — ${theme.name}`}</title>
            </Helmet>

            <BlockMap/>

            <PageHeader header="Contact Us" breadcrumb={breadcrumb}/>

            <div className="block">
                <div className="container">
                    <div className="card mb-0">
                        <div className="card-body contact-us">
                            <div className="contact-us__container">
                                <div className="row">
                                    <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                                        {/*<h4 className="contact-us__header card-title">Contact information</h4>*/}
                                        <div className="contactList">Information and enquiries:</div>
                                        <div className="contactEmail">info@bookingstock.co.uk</div>
                                          <div className="contactList">Orders, products and returns:</div>
                                        <div className="contactEmail">support@bookingstock.co.uk</div>





                                        {/*<div>*/}
                                        {/*    <ul className="footer-contacts__contacts contact-links">*/}
                                        {/*        /!* REDMAN <li>*!/*/}
                                        {/*        /!*    <i className="footer-contacts__icon fas fa-globe-americas" />*!/*/}
                                        {/*        /!*    {theme.contacts.address}*!/*/}
                                        {/*        /!*</li>*!/*/}
                                        {/*        <li>*/}
                                        {/*            /!*<i className="footer-contacts__icon fa fa-envelope"/>*!/*/}
                                        {/*            /!*{theme.contacts.email}*!/*/}
                                        {/*            order@bookingstock.co.uk*/}
                                        {/*        </li>*/}
                                        {/*        <li>*/}
                                        {/*            /!*<i className="footer-contacts__icon fa fa-question"/>*!/*/}
                                        {/*            support@bookingstock.co.uk*/}
                                        {/*        </li>*/}
                                        {/*        <li>*/}
                                        {/*            /!*<i className="footer-contacts__icon fa fa-box"/>*!/*/}
                                        {/*            returns@bookingstock.co.uk*/}
                                        {/*        </li>*/}
                                        {/*        /!*<li>*!/*/}
                                        {/*        /!*    <i className="footer-contacts__icon fa fa-envelope" />*!/*/}
                                        {/*        /!*    Sat 10:00am - 4:00pm*!/*/}
                                        {/*        /!*</li>*!/*/}
                                        {/*    </ul>*/}
                                        {/*</div>*/}
                                        <div className="contact-us__address">


                                            <p>
                                                <strong>Registered Office</strong>
                                                <br/>
                                                <span style={{"font-size": "14px"}}>
                                                    <br/>c
                                                    85 Great Portland Street, First Floor,
                                                    <br/>
                                                    London W1W 7LT
                                                    <br/>
                                                    Reg Number: 13422501
                                                    <br/>
                                                    VAT Number: 405264031
                                                </span>
                                            </p>
                                            {/*<p>*/}

                                            {/*    <strong>Comment</strong>*/}
                                            {/*    <br />*/}
                                            {/*    Lorem ipsum dolor sit amet, consectetur adipiscing*/}
                                            {/*    elit. Curabitur suscipit suscipit mi, non tempor*/}
                                            {/*    nulla finibus eget. Lorem ipsum dolor sit amet,*/}
                                            {/*    consectetur adipiscing elit.*/}
                                            {/*</p>*/}
                                        </div>

                                    </div>


                                    {!showThanks &&
                                        <div className="col-12 col-lg-6">
                                            <h4 className="contact-us__header card-title">Leave us a Message</h4>

                                            <form>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="form-name">Your Name</label>
                                                        <input type="text" id="form-name" className="form-control"
                                                               value={name} name="name"
                                                               onChange={e => setName(e.target.value)}
                                                               placeholder="Your Name"/>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="form-email">Email</label>
                                                        <input
                                                            value={email}
                                                            onChange={e => setEmail(e.target.value)}
                                                            name="email"
                                                            type="email"
                                                            id="form-email"
                                                            className="form-control"
                                                            placeholder="Email Address"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="form-subject">Subject</label>
                                                    <input type="text" id="form-subject" value={subject}
                                                           onChange={e => setSubject(e.target.value)}
                                                           className="form-control" name="subject"
                                                           placeholder="Subject"/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="form-message">Message</label>
                                                    <textarea id="form-message" className="form-control" name="message"
                                                              value={message}
                                                              onChange={e => setMessage(e.target.value)} rows="4"/>
                                                </div>
                                                <button type="submit" className="btn btn-primary"
                                                        onClick={submitForm}>Send
                                                    Message
                                                </button>
                                            </form>
                                            {
                                                Object.entries(errors)
                                                    .map(([key, value]) => <div key={key.charAt(0).toUpperCase()}>
                                                        <b>{key.charAt(0).toUpperCase() + key.slice(1)}</b> - {value}
                                                    </div>)

                                            }
                                        </div>
                                    }
                                    {showThanks &&
                                        <div className="col-12 col-lg-6">
                                            <h4 className="contact-us__header card-title">Thanks! We'll get back to you
                                                ASAP.</h4>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageContactUs;
