// react
import React from 'react';

function createMarkup(text) {
    return {__html: text};
}

function ProductTabDescription(props) {
    return (

        <div className="spec">
            <h3 className="spec__header">{props.product.name}</h3>

            {props.product.variants.map(function (variant, i) {

                return (

                    <div className="table">

                        <div className="tableVariant listVariant">{variant.name}</div>
                        {/*<div className="tableDetail">{variant.description}</div>*/}
                        <div className="tableDetail" dangerouslySetInnerHTML={createMarkup(variant.description)}></div>
                        <div className="productCode">Product Code: <span dangerouslySetInnerHTML= {createMarkup(variant.code)}></span></div>
                        <div className="spacer"/>

                    </div>
                )
            })}

            {/*<h3>Product Full Description</h3>*/}
            {/*<p>*/}
            {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas fermentum, diam*/}
            {/*    non iaculis finibus, ipsum arcu sollicitudin dolor, ut cursus sapien sem sed*/}
            {/*    purus. Donec vitae fringilla tortor, sed fermentum nunc. Suspendisse sodales turpis*/}
            {/*    dolor, at rutrum dolor tristique id. Quisque pellentesque ullamcorper felis, eget*/}
            {/*    gravida mi elementum a. Maecenas consectetur volutpat ante, sit amet molestie urna*/}
            {/*    luctus in. Nulla eget dolor semper urna malesuada dictum. Duis eleifend*/}
            {/*    pellentesque dui et finibus. Pellentesque dapibus dignissim augue. Etiam odio est,*/}
            {/*    sodales ac aliquam id, iaculis eget lacus. Aenean porta, ante vitae suscipit*/}
            {/*    pulvinar, purus dui interdum tellus, sed dapibus mi mauris vitae tellus.*/}
            {/*</p>*/}
            {/*<h3>Etiam lacus lacus mollis in mattis</h3>*/}
            {/*<p>*/}
            {/*    Praesent mattis eget augue ac elementum. Maecenas vel ante ut enim mollis accumsan.*/}
            {/*    Vestibulum vel eros at mi suscipit feugiat. Sed tortor purus, vulputate et eros a,*/}
            {/*    rhoncus laoreet orci. Proin sapien neque, commodo at porta in, vehicula eu elit.*/}
            {/*    Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia*/}
            {/*    Curae; Curabitur porta vulputate augue, at sollicitudin nisl molestie eget.*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*    Nunc sollicitudin, nunc id accumsan semper, libero nunc aliquet nulla, nec pretium*/}
            {/*    ipsum risus ac neque. Morbi eu facilisis purus. Quisque mi tortor, cursus in nulla*/}
            {/*    ut, laoreet commodo quam. Pellentesque et ornare sapien. In ac est tempus urna*/}
            {/*    tincidunt finibus. Integer erat ipsum, tristique ac lobortis sit amet, dapibus sit*/}
            {/*    amet purus. Nam sed lorem nisi. Vestibulum ultrices tincidunt turpis, sit amet*/}
            {/*    fringilla odio scelerisque non.*/}
            {/*</p>*/}
        </div>
    );
}

export default ProductTabDescription;
