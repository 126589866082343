// react
import React from 'react';

// third-party
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
// application
import * as serviceWorker from './serviceWorker';
import Root from './components/Root';
import store from './store';

// styles
import 'slick-carousel/slick/slick.css';
import 'react-toastify/dist/ReactToastify.min.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-input-range/lib/css/index.css';
import './scss/style.scss';
import axios from "axios";


(function (history) {
    var pushState = history.pushState;
    history.pushState = function (state) {
        if (typeof history.onpushstate == "function") {
            history.onpushstate({state: state});
        }

        setTimeout(function () {
            console.log(window.location.pathname + window.location.search);
            //ReactGA.pageview(window.location.pathname + window.location.search);

            if ("ga" in window) {
                try {
                    var path = window.location.pathname + window.location.search;
                    var tracker = window.ga.getAll()[0];
                    tracker.set("page", path);
                    tracker.send("event", "pageview");
                    console.log("sending GA page", path);
                } catch (err) {
                    console.log(err);
                }
            }

        }, 300);

        //console.log(window.location.pathname);
        // ... whatever else you want to do
        // maybe call onhashchange e.handler
        return pushState.apply(history, arguments);
    };
})(window.history);

console.log(window.location.pathname + window.location.search);
//ReactGA.pageview(window.location.pathname + window.location.search);
var path = window.location.pathname + window.location.search;
if ("ga" in window) {
    try {
        var tracker = window.ga.getAll()[0];
        tracker.set("page", path);
        tracker.send("event", "pageview");
        console.log("sending GA page", path);
    } catch (err) {
        console.log(err);
    }
}


axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

Sentry.init({
    dsn: "https://6fc9cbff8ae64c2380712985e505d0b8@o1027192.ingest.sentry.io/6249003",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

ReactDOM.render((
    // eslint-disable-next-line react/jsx-filename-extension
    <Provider store={store}>
        <Root/>
    </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

window.test = function () {
    console.log("hello");
}
