// react
import React from 'react';

// third-party
import {Helmet} from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';
import {Link} from "react-router-dom";

function SitePageFaq() {
    const breadcrumb = [
        {title: 'Home', url: ''},
        {title: 'Frequently asked Questions', url: ''},
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`FAQ — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Frequently Asked Questions" breadcrumb={breadcrumb}/>

            <div className="block faq">
                <div className="container">
                    <div className="faq__section">
                        {/*<div className="faq__section-title">*/}
                        {/*    <h3>Delivery</h3>*/}
                        {/*</div>*/}
                        <div className="faq__section-body">
                            <div className="row">
                                <div className="faq__section-column col-12 col-lg-12">
                                    <div className="typography">
                                        <h4>What are your dilivery times?</h4>

                                        <p>
                                            The delivery lead time is within 3-5 working days after the order is confirmed and when the product
is in stock. The delivery lead time can be up to 60 days where an item is out of stock.
                                        </p>

                                        {/*<h6>Do you ship internationally?</h6>*/}

                                        {/*<p>*/}
                                        {/*   */}
                                        {/*</p>*/}
                                    </div>
                                </div>
                                {/*<div className="faq__section-column col-12 col-lg-12">*/}
                                {/*    /!*<div className="typography">*!/*/}
                                {/*    /!*    <h6>What shipping methods are available?</h6>*!/*/}

                                {/*    /!*    <p>*!/*/}
                                {/*    /!*        *!/*/}
                                {/*    /!*    </p>*!/*/}

                                {/*    /!*    <h6>Do you ship internationally?</h6>*!/*/}

                                {/*    /!*    <p>*!/*/}
                                {/*    /!*        *!/*/}
                                {/*    /!*    </p>*!/*/}
                                {/*    /!*</div>*!/*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>

                    <div className="faq__section">
                        {/*<div className="faq__section-title">*/}
                        {/*    <h3>Pricing </h3>*/}
                        {/*</div>*/}
                        <div className="faq__section-body">
                            <div className="row">
                                <div className="faq__section-column col-12 col-lg-12">
                                    <div className="typography">
                                        <h4>How are items priced?</h4>

                                        <p>
                                            All prices are net. VAT will be added on at the Check Out. The delivery is free if the total net order
value is £15 and over. The one off delivery charge of £5 will be added to your order if the order is
under £15.

                                        </p>
                                    </div>
                                </div>
                                <div className="faq__section-column col-12 col-lg-6">
                                    {/*                                    <div className="typography">*/}
                                    {/*                                        <h6>Q: Are there any additional costs not included in the booking price?</h6>*/}

                                    {/*                                        <p>*/}
                                    {/*                                           The price listed on our website is all inclusive, including VAT and delivery to a UK address. We will not charge any extra unless pre notified and agreed before the booking*/}
                                    {/*confirmation.*/}
                                    {/*                                        </p>*/}
                                    {/*                                    </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="faq__section">
                        {/*<div className="faq__section-title">*/}
                        {/*    <h3>Pricing </h3>*/}
                        {/*</div>*/}
                        <div className="faq__section-body">
                            <div className="row">
                                <div className="faq__section-column col-12 col-lg-12">
                                    <div className="typography">
                                        <h4>What if an item is out of stock?</h4>

                                        <p>
                                            Please <Link to="/site/contact-us">email us</Link> if you want to order an
                                            item that is out of stock.
                                        </p>
                                    </div>
                                </div>
                                {/*                                <div className="faq__section-column col-12 col-lg-6">*/}
                                {/*/!*                                    <div className="typography">*!/*/}
                                {/*/!*                                        <h6>Q: Are there any additional costs not included in the booking price?</h6>*!/*/}

                                {/*/!*                                        <p>*!/*/}
                                {/*/!*                                           The price listed on our website is all inclusive, including VAT and delivery to a UK address. We will not charge any extra unless pre notified and agreed before the booking*!/*/}
                                {/*/!*confirmation.*!/*/}
                                {/*/!*                                        </p>*!/*/}
                                {/*/!*                                    </div>*!/*/}
                                {/*                                </div>*/}
                            </div>
                        </div>
                    </div>

                    {/*REDMAN <div className="faq__section">*/}
                    {/*    <div className="faq__section-title">*/}
                    {/*        <h3>Orders and Returns</h3>*/}
                    {/*    </div>*/}
                    {/*    <div className="faq__section-body">*/}
                    {/*        <div className="row">*/}
                    {/*            <div className="faq__section-column col-12 col-lg-6">*/}
                    {/*                <div className="typography">*/}
                    {/*                    <h6>What shipping methods are available?</h6>*/}

                    {/*                    <p>*/}
                    {/*                        Lorem ipsum dolor sit amet conse ctetur adipisicing*/}
                    {/*                        elit, sed do eiusmod tempor incididunt ut labore et*/}
                    {/*                        dolore magna aliqua. Ut enim ad minim veniam, quis*/}
                    {/*                        nostrud exercitation ullamco laboris nisi ut aliquip*/}
                    {/*                        ex ea commodo consequat. Duis aute irure dolor in*/}
                    {/*                        reprehenderit in voluptate velit esse cillum dolore*/}
                    {/*                        eu fugiat.*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="faq__section-column col-12 col-lg-6">*/}
                    {/*                <div className="typography">*/}
                    {/*                    <h6>What shipping methods are available?</h6>*/}

                    {/*                    <p>*/}
                    {/*                        Lorem ipsum dolor sit amet conse ctetur adipisicing*/}
                    {/*                        elit, sed do eiusmod tempor incididunt ut labore et*/}
                    {/*                        dolore magna aliqua. Ut enim ad minim veniam, quis*/}
                    {/*                        nostrud exercitation ullamco laboris nisi ut aliquip*/}
                    {/*                        ex ea commodo consequat. Duis aute irure dolor in*/}
                    {/*                        reprehenderit in voluptate velit esse cillum dolore*/}
                    {/*                        eu fugiat.*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageFaq;
// react
// import React from 'react';
//
// // third-party
// import { Helmet } from 'react-helmet-async';
//
// // application
// import PageHeader from '../shared/PageHeader';
//
// // data stubs
// import theme from '../../data/theme';
// import {Link} from "react-router-dom";
//
// function SitePageFaq() {
//     const breadcrumb = [
//         { title: 'Home', url: '' },
//         { title: 'Frequently asked Questions', url: '' },
//     ];
//
//     return (
//         <React.Fragment>
//             <Helmet>
//                 <title>{`FAQ — ${theme.name}`}</title>
//             </Helmet>
//
//             <PageHeader header="Frequently Asked Questions" breadcrumb={breadcrumb} />
//
//             <div className="block faq">
//                 <div className="container">
//                     <div className="faq__section">
//                         {/*<div className="faq__section-title">*/}
//                         {/*    <h3>Delivery</h3>*/}
//                         {/*</div>*/}
//                         <div className="faq__section-body">
//                             <div className="row">
//                                 <div className="faq__section-column col-12 col-lg-12">
//                                     <div className="typography">
//                                         <h4>What are your dilivery times?</h4>
//
//                                         <p>
//                                             The delivery lead time is within 3-5 working days after the order confirmed and when the products in Stock. The delivery lead time would be up to 40-60 days if Out of Stock.
//                                         </p>
//
//                                         {/*<h6>Do you ship internationally?</h6>*/}
//
//                                         {/*<p>*/}
//                                         {/*   */}
//                                         {/*</p>*/}
//                                     </div>
//                                 </div>
//                                 {/*<div className="faq__section-column col-12 col-lg-12">*/}
//                                 {/*    /!*<div className="typography">*!/*/}
//                                 {/*    /!*    <h6>What shipping methods are available?</h6>*!/*/}
//
//                                 {/*    /!*    <p>*!/*/}
//                                 {/*    /!*        *!/*/}
//                                 {/*    /!*    </p>*!/*/}
//
//                                 {/*    /!*    <h6>Do you ship internationally?</h6>*!/*/}
//
//                                 {/*    /!*    <p>*!/*/}
//                                 {/*    /!*        *!/*/}
//                                 {/*    /!*    </p>*!/*/}
//                                 {/*    /!*</div>*!/*/}
//                                 {/*</div>*/}
//                             </div>
//                         </div>
//                     </div>
//
//                     <div className="faq__section">
//                         {/*<div className="faq__section-title">*/}
//                         {/*    <h3>Pricing </h3>*/}
//                         {/*</div>*/}
//                         <div className="faq__section-body">
//                             <div className="row">
//                                 <div className="faq__section-column col-12 col-lg-12">
//                                     <div className="typography">
//                                         <h4>How are items priced?</h4>
//
//                                         <p>
// All prices are net. VAT will be added on at the Check Out. The delivery is free if total net order value is £10 and over. The one off delivery charge of £5 will be added to your order if the order under £10.
//
//                                         </p>
//                                     </div>
//                                 </div>
//                                 <div className="faq__section-column col-12 col-lg-6">
// {/*                                    <div className="typography">*/}
// {/*                                        <h6>Q: Are there any additional costs not included in the booking price?</h6>*/}
//
// {/*                                        <p>*/}
// {/*                                           The price listed on our website is all inclusive, including VAT and delivery to a UK address. We will not charge any extra unless pre notified and agreed before the booking*/}
// {/*confirmation.*/}
// {/*                                        </p>*/}
// {/*                                    </div>*/}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="faq__section">
//                         {/*<div className="faq__section-title">*/}
//                         {/*    <h3>Pricing </h3>*/}
//                         {/*</div>*/}
//                         <div className="faq__section-body">
//                             <div className="row">
//                                 <div className="faq__section-column col-12 col-lg-12">
//                                     <div className="typography">
//                                         <h4>What if an item is out of stock?</h4>
//
//                                         <p>
// A:	Please <Link to="/site/contact-us">email us</Link> if you want to order the items are Out of Stock, we will be in touch with you for updated information.
//                                         </p>
//                                     </div>
//                                 </div>
// {/*                                <div className="faq__section-column col-12 col-lg-6">*/}
// {/*/!*                                    <div className="typography">*!/*/}
// {/*/!*                                        <h6>Q: Are there any additional costs not included in the booking price?</h6>*!/*/}
//
// {/*/!*                                        <p>*!/*/}
// {/*/!*                                           The price listed on our website is all inclusive, including VAT and delivery to a UK address. We will not charge any extra unless pre notified and agreed before the booking*!/*/}
// {/*/!*confirmation.*!/*/}
// {/*/!*                                        </p>*!/*/}
// {/*/!*                                    </div>*!/*/}
// {/*                                </div>*/}
//                             </div>
//                         </div>
//                     </div>
//
//                     {/*REDMAN <div className="faq__section">*/}
//                     {/*    <div className="faq__section-title">*/}
//                     {/*        <h3>Orders and Returns</h3>*/}
//                     {/*    </div>*/}
//                     {/*    <div className="faq__section-body">*/}
//                     {/*        <div className="row">*/}
//                     {/*            <div className="faq__section-column col-12 col-lg-6">*/}
//                     {/*                <div className="typography">*/}
//                     {/*                    <h6>What shipping methods are available?</h6>*/}
//
//                     {/*                    <p>*/}
//                     {/*                        Lorem ipsum dolor sit amet conse ctetur adipisicing*/}
//                     {/*                        elit, sed do eiusmod tempor incididunt ut labore et*/}
//                     {/*                        dolore magna aliqua. Ut enim ad minim veniam, quis*/}
//                     {/*                        nostrud exercitation ullamco laboris nisi ut aliquip*/}
//                     {/*                        ex ea commodo consequat. Duis aute irure dolor in*/}
//                     {/*                        reprehenderit in voluptate velit esse cillum dolore*/}
//                     {/*                        eu fugiat.*/}
//                     {/*                    </p>*/}
//                     {/*                </div>*/}
//                     {/*            </div>*/}
//                     {/*            <div className="faq__section-column col-12 col-lg-6">*/}
//                     {/*                <div className="typography">*/}
//                     {/*                    <h6>What shipping methods are available?</h6>*/}
//
//                     {/*                    <p>*/}
//                     {/*                        Lorem ipsum dolor sit amet conse ctetur adipisicing*/}
//                     {/*                        elit, sed do eiusmod tempor incididunt ut labore et*/}
//                     {/*                        dolore magna aliqua. Ut enim ad minim veniam, quis*/}
//                     {/*                        nostrud exercitation ullamco laboris nisi ut aliquip*/}
//                     {/*                        ex ea commodo consequat. Duis aute irure dolor in*/}
//                     {/*                        reprehenderit in voluptate velit esse cillum dolore*/}
//                     {/*                        eu fugiat.*/}
//                     {/*                    </p>*/}
//                     {/*                </div>*/}
//                     {/*            </div>*/}
//                     {/*        </div>*/}
//                     {/*    </div>*/}
//                     {/*</div>*/}
//                 </div>
//             </div>
//         </React.Fragment>
//     );
// }
//
// export default SitePageFaq;
