// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';

// application
import {
    Fi24Hours48Svg,
    FiFreeDelivery48Svg,
    FiPaymentSecurity48Svg,
    FiTag48Svg,
} from '../../svg';

export default function BlockFeatures(props) {
    const { layout } = props;

    return (
        <div className={`block block-features block-features--layout--${layout}`}>
            <div className="container">
                <div className="block-features__list">
                    {/*<div className="block-features__item">*/}
                    {/*    <div className="block-features__icon">*/}
                    {/*        <FiFreeDelivery48Svg />*/}
                    {/*    </div>*/}
                    {/*    <div className="block-features__content">*/}
                    {/*        <div className="block-features__title">Fast delivery</div>*/}
                    {/*        <div className="block-features__subtitle">Within 5 working days</div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="block-features__divider" />*/}
                    {/*<div className="block-features__item">*/}
                    {/*    <div className="block-features__icon">*/}
                    {/*        <FiFreeDelivery48Svg />*/}
                    {/*    </div>*/}
                    {/*    <div className="block-features__content">*/}
                    {/*        <div className="block-features__title">Fast delivery</div>*/}
                    {/*        <div className="block-features__subtitle">Within 5 working days</div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*/!*<div className="block-features__divider" />*!/*/}
                    {/*/!*<div className="block-features__item">*!/*/}
                    {/*/!*    <div className="block-features__icon">*!/*/}
                    {/*/!*        <Fi24Hours48Svg />*!/*/}
                    {/*/!*    </div>*!/*/}
                    {/*/!*    <div className="block-features__content">*!/*/}
                    {/*/!*        <div className="block-features__title">Professional service</div>*!/*/}
                    {/*/!*        <div className="block-features__subtitle">Contact us anytime</div>*!/*/}
                    {/*/!*    </div>*!/*/}
                    {/*/!*</div>*!/*/}
                    {/*<div className="block-features__divider" />*/}
                    <div className="block-features__item flexGrow2">
                        <div className="block-features__icon">
                            <FiTag48Svg />
                        </div>
                        <div className="block-features__content">
                            <div className="homepageOffer">
                            <div className="block-features__title">More Offers</div>
                            <div className="block-features__subtitle">Sign up to our newsletter for exclusive disounts</div></div>
                        </div>
                    {/*</div>*/}
                    {/*<div className="block-features__divider" />*/}
                    {/*<div className="block-features__item">*/}
                        {/*<div className="block-features__icon">*/}
                        {/*    <FiTag48Svg />*/}
                        {/*</div>*/}
                        <div className="block-features__content">
                            {/*<div className="block-features__title">10% off</div>*/}
                            {/*<div className="block-features__subtitle">Sign up to our newsletter for information on, special offers and new product lines.</div>*/}
                            <div id="mc_embed_signup">
                                <form
                                    action="https://bookingstock.us14.list-manage.com/subscribe/post?u=c321dc83dabaa169bea0fa483&amp;id=2dc2d0f56a"
                                    method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
                                    className="footer-newsletter__form"
                                    target="_blank" noValidate>
                                    <div id="mc_embed_signup_scroll">
                                        {/*<div className="indicates-required"><span className="asterisk">*</span> indicates required</div>*/}
                                        <div className="mc-field-group">
                                            <label className="sr-only" htmlFor="footer-newsletter-address">Email
                                                Address</label>
                                            {/*<label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span>*/}
                                            {/*</label>*/}
                                            <input style={{"display": "inline-block", "width": "100%"}} type="email"
                                                   name="EMAIL"
                                                   className="required email footer-newsletter__form-input form-control"
                                                   placeholder="Email Address..."/>
                                            <button style={{"display": "inline-block", "width": "100%", "margin-left" : "0px"}} type="submit"
                                                    value="Subscribe"
                                                    name="subscribe"
                                                    id="mc-embedded-subscribe"
                                                    className="button footer-newsletter__form-button btn btn-primary">Subscribe
                                            </button>
                                        </div>
                                        <div id="mce-responses" className="clear foot">
                                            <div className="response" id="mce-error-response"
                                                 style={{"display": "none"}}></div>
                                            <div className="response" id="mce-success-response"
                                                 style={{"display": "none"}}></div>
                                        </div>

                                        <div style={{"position": "absolute", "left": "-5000px"}} aria-hidden="true">
                                            <input type="text"
                                                   name="b_c321dc83dabaa169bea0fa483_2dc2d0f56a"
                                                   tabIndex="-1"
                                                   value=""/></div>
                                        <div className="optionalParent">
                                            <div className="clear foot">
                                                {/*<input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe"*/}
                                                {/*       className="button"/>*/}

                                                {/*<p className="brandingLogo"><a href="http://eepurl.com/h5IY6f"*/}
                                                {/*                               title="Mailchimp - email marketing made easy and fun"><img*/}
                                                {/*    src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"/></a>*/}
                                                {/*</p>*/}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

BlockFeatures.propTypes = {
    layout: PropTypes.oneOf(['classic', 'boxed']),
};

BlockFeatures.defaultProps = {
    layout: 'classic',
};
