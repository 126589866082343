// react
import React from 'react';

export default function BlockMap() {
    return (
        <div className="block-map block">
            {/*REDMAN BARRY - I have hacked this out, might be a cleaner way to not include it <div className="block-map__body">*/}
            {/*    <iframe*/}
            {/*        title="Google Map"*/}
            {/*        src="https://maps.google.com/maps?q=Holbrook-Palmer%20Park&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"*/}
            {/*        frameBorder="0"*/}
            {/*        scrolling="no"*/}
            {/*        marginHeight="0"*/}
            {/*        marginWidth="0"*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    );
}
