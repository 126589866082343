/*jshint esversion: 6 */
import axios from 'axios';

const userEndpoint = "/api/users";
const userAccountEndpoint = `${userEndpoint}/account`;

const userApi = {
    /**
     * Returns TBC.
     *
     * @param {string} email
     * @param {string} password
     *
     * @return {Promise<object>}
     */
    register: (email, password) => {
        return axios.post(`${userAccountEndpoint}/register/`, {
            email: email,
            password1: password,
            password2: password
        });
    },
    /**
     * Returns TBC.
     *
     * @param {string} email
     * @param {string} password
     *
     * @return {Promise<object>}
     */
    signIn: (email, password) => {
        return axios.post(`${userAccountEndpoint}/signin/`, {
            email: email,
            password: password,
        });
    },
    /**
     * Signs the user out, returns axios promise.
     *
     * @return {Promise<object>}
     */
    signOut: () => {
        return axios.post(`${userAccountEndpoint}/signout/`);
    },
    /**
     * Returns TBC.
     *
     * @param {string} email
     *
     * @return {Promise<object>}
     */
    passwordReset: (email) => {
        return axios.post(`${userAccountEndpoint}/password/reset/`, {
            email: email
        });
    },
    passwordChange: (password) => {
        return axios.post(`${userAccountEndpoint}/password/change/`, {
            "new_password1": password,
            "new_password2": password
        });
    },
    getUser: () => {
        return axios.get(`${userAccountEndpoint}/user/`);
    },
    /**
     * Returns TBC.
     *
     * @param {object} user
     *
     * @return {Promise<object>}
     */
    updateUser: (user) => {
        return axios.put(`${userAccountEndpoint}/user/`, user);
    }


};

export default userApi;
