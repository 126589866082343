export default [
    {
        // title: 'Login',
        // url: '/accounts/login',
        // submenu: {
        //     type: 'menu',
        //     menu: [
        //         {title: 'Home 1', url: '/'},
        //         {title: 'Home 2', url: '/home-two'},
        //         {title: 'Home 3', url: '/home-three'},
        //     ],
        // },
    },
];
