// react
import React from 'react';

// application
import SocialLinks from '../shared/SocialLinks';

export default function FooterNewsletter() {
    return (
        <div className="site-footer__widget footer-newsletter">
            <h5 className="footer-newsletter__title">Newsletter</h5>
            <div className="footer-newsletter__text">
                Sign up to our newsletter for information on, special offers and new product lines.
            </div>


            <div id="mc_embed_signup">
                <form
                    action="https://bookingstock.us14.list-manage.com/subscribe/post?u=c321dc83dabaa169bea0fa483&amp;id=2dc2d0f56a"
                    method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
                    className="footer-newsletter__form"
                    target="_blank" novalidate>
                    <div id="mc_embed_signup_scroll">
                        {/*<div className="indicates-required"><span className="asterisk">*</span> indicates required</div>*/}
                        <div className="mc-field-group">
                            <label className="sr-only" htmlFor="footer-newsletter-address">Email Address</label>
                            {/*<label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span>*/}
                            {/*</label>*/}
                            <input style={{"display": "inline-block", "width": "49%"}} type="email" name="EMAIL"
                                   className="required email footer-newsletter__form-input form-control"
                                   placeholder="Email Address..."/>
                            <button style={{"display": "inline-block", "width": "49%"}} type="submit" value="Subscribe"
                                    name="subscribe"
                                    id="mc-embedded-subscribe"
                                    className="button footer-newsletter__form-button btn btn-primary">Subscribe
                            </button>
                        </div>
                        <div id="mce-responses" className="clear foot">
                            <div className="response" id="mce-error-response" style={{"display": "none"}}></div>
                            <div className="response" id="mce-success-response" style={{"display": "none"}}></div>
                        </div>

                        <div style={{"position": "absolute", "left": "-5000px"}} aria-hidden="true">
                            <input type="text"
                                   name="b_c321dc83dabaa169bea0fa483_2dc2d0f56a"
                                   tabIndex="-1"
                                   value=""/></div>
                        <div className="optionalParent">
                            <div className="clear foot">
                                {/*<input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe"*/}
                                {/*       className="button"/>*/}

                                {/*<p className="brandingLogo"><a href="http://eepurl.com/h5IY6f"*/}
                                {/*                               title="Mailchimp - email marketing made easy and fun"><img*/}
                                {/*    src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"/></a>*/}
                                {/*</p>*/}
                            </div>
                        </div>
                    </div>
                </form>
            </div>


            {/*<form action="" className="footer-newsletter__form">*/}
            {/*    <label className="sr-only" htmlFor="footer-newsletter-address">Email Address</label>*/}
            {/*    <input*/}
            {/*        type="text"*/}
            {/*        className="footer-newsletter__form-input form-control"*/}
            {/*        id="footer-newsletter-address"*/}
            {/*        placeholder="Email Address..."*/}
            {/*    />*/}
            {/*    <button type="submit" className="footer-newsletter__form-button btn btn-primary">Subscribe</button>*/}
            {/*</form>*/}

            {/* REDMAN<div className="footer-newsletter__text footer-newsletter__text--social">*/}
            {/*    Follow us on social networks*/}
            {/*</div>*/}

            <SocialLinks className="footer-newsletter__social-links" shape="circle"/>
        </div>
    );
}
