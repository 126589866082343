// react
import React from 'react';

// third-party
import {Helmet} from 'react-helmet-async';
import {Link} from 'react-router-dom';

// application
import StroykaSlick from '../shared/StroykaSlick';

// data stubs
import theme from '../../data/theme';

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 379,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function SitePageIframe() {

    var pathname = window.location.pathname;
    if (!pathname.endsWith("/")) {
        pathname += "/";
    }

    return (
        <div className="block about-us">
            <Helmet>
                <title>{`About Us — ${theme.name}`}</title>
            </Helmet>

            <div className="about-us__image"
                 style={{backgroundImage: 'url("https://storage.googleapis.com/bookingstock/static/images/aboutus.jpg")'}}/>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body">
                            {/*<h1 className="about-us__title">About Us</h1>*/}
                            <div className="about-us__text typography">

                                {/*{{*/}
                                {/*    var pathname = window.location.pathname;*/}
                                {/*    if (!pathname.endsWith("/")){*/}
                                {/*    pathname += "/";*/}
                                {/*}}*/}

                                <iframe style={{"border": "0px", "width": "100%", "height": "500px"}}
                                        src={'embed' + pathname}
                                        sandbox="allow-same-origin allow-top-navigation allow-scripts allow-forms"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageIframe;
