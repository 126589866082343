// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';

function SitePageTerms() {
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Terms And Conditions', url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Terms And Conditions — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <h1 className="document__title">Terms And Conditions</h1>
                            <div className="document__subtitle">This Agreement was last modified on 17th March 2022.</div>
                        </div>
                        <div className="document__content typography">
                            <p>Please read these Terms of Use ("Terms", "Terms of Use") carefully before using this website and the mobile application (the "Service") operated by Booking Stock Ltd.</p>
                            <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>

<p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>



                            <h6>Purchases</h6>
                            <p>
If you wish to purchase any product or service made available through the Service ("Purchase"), you may be asked to supply certain information relevant to your Purchase including, without limitation, your name; contact details; address and bank details and so on.                            </p>
                            <h6>Termination</h6>
                            <p>We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
                            <h6>Communications</h6>
                            <p>
We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information that may interested to you.                            </p>
                            <h6>Links To Other Web Sites</h6>
                            <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by us.

                                We has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
                            <h6>Changes</h6>
                            <p>
The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.                            </p>
                            <h6>Changes to This Privacy Policy</h6>
                            <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
                            </p>
                                <h6>Contact Us</h6>

                            <p>
                               If you have any questions about these Terms, please  <Link to="/site/contact-us">contact page</Link>
                                
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageTerms;
