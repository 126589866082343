// react
import React, {useEffect, useState} from 'react';

// third-party
import {Helmet} from 'react-helmet-async';

// data stubs
import theme from '../../data/theme';
import axios from "axios";

export default function AccountPageEditAddress(props) {

    const [data, setData] = useState({});

    useEffect(() => {
        axios.get("/api/catalog/get-address/" + props.match.params.addressId + "/").then(function (response) {
            setData(response.data);
            console.log(response.data);
        })
    }, []) // <-- empty dependency array

    const setField = (e, fieldName) => {
        console.log(e);
        console.log(fieldName);
        const d = {...data};
        console.log(d)
        d[fieldName] = e.target.value;
        setData(d);
    }

    const saveData = e => { // use consts or let
        e.preventDefault()

        axios.post("/api/catalog/set-address/" + props.match.params.addressId + "/", data).then(function (response) {
            //setData(response.data);
        })
    }

    return (
        <div className="card">
            <Helmet>
                <title>{`Edit Address — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>Edit Address</h5>
            </div>
            <div className="card-divider"/>
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-10 col-xl-8">
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="checkout-first-name">Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={data.name}
                                    onChange={((e) => setField(e, "name"))}
                                    id="checkout-first-name"
                                    placeholder="First Name"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="checkout-company-name">
                                Company Name
                                {' '}
                                <span className="text-muted">(Optional)</span>
                            </label>
                            <input
                                type="text"
                                value={data.company_name}
                                onChange={((e) => setField(e, "company_name"))}
                                className="form-control"
                                id="checkout-company-name"
                                placeholder="Company Name"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-country">Country</label>
                            <select id="checkout-country" className="form-control form-control-select2">
                                <option>Select a country...</option>
                                <option selected>United Kingdom</option>
                                {/*<option>Russia</option>*/}
                                {/*<option>Italy</option>*/}
                                {/*<option>France</option>*/}
                                {/*<option>Ukraine</option>*/}
                                {/*<option>Germany</option>*/}
                                {/*<option>Australia</option>*/}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-street-address">Street Address</label>
                            <input
                                type="text"
                                value={data.line_1}
                                onChange={((e) => setField(e, "line_1"))}
                                className="form-control"
                                id="checkout-street-address"
                                placeholder="Street Address"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-address">
                                Apartment, suite, unit etc.
                                {' '}
                                <span className="text-muted">(Optional)</span>
                            </label>
                            <input
                                value={data.line_2}
                                onChange={((e) => setField(e, "line_2"))}
                                type="text"
                                className="form-control"
                                id="checkout-address"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-city">Town / City</label>
                            <input
                                value={data.city}
                                onChange={((e) => setField(e, "city"))}
                                type="text"
                                className="form-control"
                                id="checkout-city"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-state">County</label>
                            <input
                                value={data.county}
                                onChange={((e) => setField(e, "county"))}
                                type="text"
                                className="form-control"
                                id="checkout-state"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-postcode">Postcode</label>
                            <input
                                value={data.postcode}
                                onChange={((e) => setField(e, "postcode"))}
                                type="text"
                                className="form-control"
                                id="checkout-postcode"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="checkout-postcode">Default address</label>
                            <input
                                defaultChecked={data.default}
                                onChange={((e) => setField(e, "default"))}
                                type="checkbox"
                                className="form-control"
                                id="checkout-postcode"
                            />
                        </div>

                        <div className="form-row">
                            {/*<div className="form-group col-md-6">*/}
                            {/*    <label htmlFor="checkout-email">Email address</label>*/}
                            {/*    <input*/}
                            {/*        type="email"*/}
                            {/*        className="form-control"*/}
                            {/*        id="checkout-email"*/}
                            {/*        placeholder="Email address"*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*<div className="form-group col-md-6">*/}
                            {/*    <label htmlFor="checkout-phone">Phone</label>*/}
                            {/*    <input*/}
                            {/*        type="text"*/}
                            {/*        className="form-control"*/}
                            {/*        id="checkout-phone"*/}
                            {/*        placeholder="Phone"*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </div>

                        <div className="form-group mt-3 mb-0">
                            <button className="btn btn-primary" type="button" onClick={saveData}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
