// react
import React from 'react';

// data stubs
import theme from '../../data/theme';

export default function FooterContacts() {
    return (
        <div className="site-footer__widget footer-contacts">
            <h5 className="footer-contacts__title">Contact Us</h5>

            {/* REDMAN <div className="footer-contacts__text">*/}
            {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in feugiat*/}
            {/*    lorem. Pellentque ac placerat tellus.*/}
            {/*</div>*/}
                                                                              <div className="contactList">Information and enquiries:</div>
                                        <div className="contactEmail">info@bookingstock.co.uk</div>
                                          <div className="contactList">Orders, products and returns:</div>
                                        <div className="contactEmail">support@bookingstock.co.uk</div>
                                                    <div className="contactEmail">VAT Number: 405 2640 31</div>

            {/*<ul className="footer-contacts__contacts">*/}
            {/*    /!* REDMAN <li>*!/*/}
            {/*    /!*    <i className="footer-contacts__icon fas fa-globe-americas" />*!/*/}
            {/*    /!*    {theme.contacts.address}*!/*/}
            {/*    /!*</li>*!/*/}
            {/*    <li>*/}
            {/*        /!*<i className="footer-contacts__icon far fa-envelope" />*!/*/}
            {/*        /!*{theme.contacts.email}*!/*/}
            {/*        supoort@bookingstock.co.uk*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*        /!*<i className="footer-contacts__icon fa fa-question" />*!/*/}
            {/*        /!*<i className="footer-contacts__icon fas fa-mobile-alt" />*!/*/}
            {/*        info@bookingstock.co.uk*/}
            {/*    </li>*/}
            {/*    /!*<li>*!/*/}
            {/*    /!*    <i className="footer-contacts__icon fa fa-box" />*!/*/}
            {/*    /!*    Mon-Fri 9:00am - 5:00pm*!/*/}
            {/*    /!*</li>*!/*/}
            {/*    /!*<li>*!/*/}
            {/*    /!*    <i className="footer-contacts__icon fa fa-envelope" />*!/*/}
            {/*    /!*    Sat 10:00am - 4:00pm*!/*/}
            {/*    /!*</li>*!/*/}
            {/*</ul>*/}
        </div>
    );
}
