import {toast} from 'react-toastify';
import {
    CART_ADD_ITEM,
    CART_ADD_ITEM_ROW,
    CART_LOAD_ALL,
    CART_REMOVE_ITEM,
    CART_UPDATE_QUANTITIES
} from './cartActionTypes';

export function cartAddItemSuccess(product, options = [], quantity = 1) {
    toast.success(`Product "${product.name}" added to cart!`);

    return {
        type: CART_ADD_ITEM,
        product,
        options,
        quantity,
    };
}

export function cartAddItemRowSuccess(product, variant, options = [], quantity = 1, cartData) {
    if (quantity > 1) {
        var cartLineItem = cartData.items.find(v => v.variant.id == variant.id);
        toast.success(cartLineItem.quantity + ` Products "${variant.name}" added to cart!`);
    } else {
        toast.success(`Product "${variant.name}" added to cart!`);
    }


    return {
        type: CART_ADD_ITEM_ROW,
        product,
        variant,
        options,
        quantity,
        cartData
    };
}

export function cartRemoveItemSuccess(itemId, cartData) {
    return {
        type: CART_REMOVE_ITEM,
        itemId,
        cartData
    };
}

export function cartUpdateQuantitiesSuccess(quantities, cartData) {
    return {
        type: CART_UPDATE_QUANTITIES,
        quantities,
        cartData
    };
}

//OK so this is actually updating the store, which only happens if the request goes through ok...
export function cartLoadAllSuccess(data) {
    return {
        type: CART_LOAD_ALL,
        data,
    };
}

export function cartAddItem(product, options = [], quantity = 1) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                alert("not using this addd item. Can duplicate from the row add");
                dispatch(cartAddItemSuccess(product, options, quantity));
                resolve();
            }, 500);
        })
    );
}

// export function cartAddItemRow(product, variant, options = [], quantity = 1) {
//     // sending request to server, timeout is used as a stub
//
//     return (dispatch) => (
//         new Promise((resolve) => {
//
//             //
//             setTimeout(() => {
//                 dispatch(cartAddItemRowSuccess(product, variant, options, quantity));
//                 resolve();
//             }, 500);
//         })
//     );
// }

export function cartAddItemRow(product, variant, options = [], quantity = 1) {
    // sending request to server, timeout is used as a stub

    return (dispatch) => (
        new Promise((resolve) => {
            fetch("/api/add_cart_item/",
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        "X-CSRFToken": window.csrftoken,
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: JSON.stringify({
                        "variant_id": variant.id,
                        "quantity": quantity
                    })
                }).then(function (response) {
                if (!response.ok) {
                    // create error object and reject if not a 2xx response code
                    let err = new Error("HTTP status code: " + response.status)
                    err.response = response
                    err.status = response.status
                    response.json().then(function (data) {

                        toast.error(data.detail);
                    });
                    //
                    resolve();
                    throw err
                }
                response.json().then(function (cartData) {
                    dispatch(cartAddItemRowSuccess(product, variant, options, quantity, cartData));
                    resolve();
                })
            })
        })
    );
}

export function cartRemoveItem(itemId) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            fetch("/api/delete_cart_item/",
                {
                    method: "DELETE",
                    headers: {
                        'Content-Type': 'application/json',
                        "X-CSRFToken": window.csrftoken,
                    },
                    body: JSON.stringify(itemId)
                }).then(function (response) {
                response.json().then(function (cartData) {
                    dispatch(cartRemoveItemSuccess(itemId, cartData));
                    resolve();
                });
            });
        })
    );
}

export function cartUpdateQuantities(quantities) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {

            fetch("/api/update_cart/",
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        "X-CSRFToken": window.csrftoken,
                    },
                    body: JSON.stringify(quantities)
                }).then(function (response) {

                if (!response.ok) {
                    // create error object and reject if not a 2xx response code
                    let err = new Error("HTTP status code: " + response.status)
                    err.response = response
                    err.status = response.status
                    response.json().then(function (data) {

                        toast.error(data.detail);
                    });
                    //
                    resolve();
                    throw err
                }

                response.json().then(function (cartData) {
                    dispatch(cartUpdateQuantitiesSuccess(quantities, cartData));
                    resolve();
                });
            });
        })
    );
}

export function cartLoadAll(data) {
    //alert("getting all cart");
    return (dispatch) => (
        new Promise((resolve) => {
            dispatch(cartLoadAllSuccess(data));
            resolve();
            //}, 500);
        })
    );
}

//Write action to update cart with entire cart data on inital load
