// react
import React, {useState} from 'react';


// third-party
import {Helmet} from 'react-helmet-async';
import {Link, useHistory} from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';
import {Check9x7Svg} from '../../svg';

// data stubs
import theme from '../../data/theme';

import userApi from "../../api/user";

import classNames from 'classnames';

export default function AccountPageLogin() {
    const breadcrumb = [
        {title: 'Home', url: ''},
        {title: 'My Account', url: ''},
    ];

    const [newEmail, setNewEmail] = useState();
    const [newPassword, setNewPassword] = useState();
    const [registerErrors, setRegisterErrors] = useState();
    const [isLoggedIn, setIsLoggedIn] = useState(window.userIsLoggedIn);
    let history = useHistory();

    const handleRegister = async e => {
        e.preventDefault();

        userApi.register(newEmail, newPassword)
            .then(function (response) {
                window.location.href = '/account/dashboard';
            })
            .catch(function (error) {
                if (error.response.data) {
                    setRegisterErrors(error.response.data);
                }

                // todo: handler errors:
                // {"email":["A user is already registered with this e-mail address."]}
                // {"password1":["This password is too short. It must contain at least 8 characters.","This password is too common.","This password is entirely numeric."]}
            });
    };

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [loginErrors, setloginErrors] = useState();
    const handleSignIn = async e => {
        e.preventDefault();
        userApi.signIn(email, password)
            .then(function (response) {
                window.location.href = '/account/dashboard';
            })
            .catch(function (error) {

                if (error.response.data) {
                    setloginErrors(error.response.data);
                }
            });
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Sign In — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="My Account" breadcrumb={breadcrumb}/>

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <h3 className="card-title">Sign In</h3>
                                    <form onSubmit={handleSignIn}>
                                        <div className="form-group">
                                            <label htmlFor="login-email">Email address</label>
                                            <input
                                                id="login-email"
                                                type="email"
                                                className={classNames("form-control", {"is-invalid": loginErrors?.email && loginErrors.email.length > 0})}
                                                placeholder="Enter email"
                                                onChange={e => setEmail(e.target.value)}
                                            />
                                            {loginErrors?.email && loginErrors.email.map(function (x, idx) {
                                                return (<div className={"invalid-feedback"} key={idx}>{x}</div>)
                                            })
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="login-password">Password</label>
                                            <input
                                                id="login-password"
                                                type="password"
                                                className={classNames("form-control", {"is-invalid": loginErrors?.password && loginErrors.password.length > 0})}
                                                placeholder="Password"
                                                onChange={e => setPassword(e.target.value)}
                                            />
                                            {loginErrors?.password && loginErrors.password.map(function (x, idx) {
                                                return (<div className={"invalid-feedback"} key={idx}>{x}</div>)
                                            })
                                            }
                                            <small className="form-text text-muted">
                                                <Link to="/account/reset">Forgotten Password</Link>
                                            </small>
                                        </div>

                                        <div className="form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input
                                                            id="login-remember"
                                                            type="checkbox"
                                                            className="input-check__input"
                                                        />
                                                        <span className="input-check__box"/>
                                                        <Check9x7Svg className="input-check__icon"/>
                                                    </span>
                                                </span>
                                                <label className="form-check-label" htmlFor="login-remember">
                                                    Remember Me
                                                </label>
                                            </div>
                                        </div>
                                        {loginErrors?.non_field_errors && loginErrors.non_field_errors.map(function (x, idx) {
                                            return (<div style={{color: 'red'}} key={idx}>{x}</div>)
                                        })
                                        }
                                        <button type="submit" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                            Login
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <h3 className="card-title">Register</h3>
                                    <form onSubmit={handleRegister}>
                                        <div className="form-group">
                                            <label htmlFor="register-email">Email address</label>
                                            <input
                                                id="register-email"
                                                type="email"
                                                className={classNames("form-control", {"is-invalid": registerErrors?.email && registerErrors.email.length > 0})}
                                                placeholder="Enter email"
                                                onChange={e => setNewEmail(e.target.value)}
                                            />
                                            {registerErrors?.email && registerErrors.email.map(function (x, idx) {
                                                return (<div className={"invalid-feedback"} key={idx}>{x}</div>)
                                            })
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="register-password">Password</label>
                                            <input
                                                id="register-password"
                                                type="password"
                                                className={classNames("form-control", {"is-invalid": registerErrors?.password1 && registerErrors.password1.length > 0})}
                                                placeholder="Password"
                                                onChange={e => setNewPassword(e.target.value)}
                                            />
                                            {registerErrors?.password1 && registerErrors.password1.map(function (x, idx) {
                                                return (<div className={"invalid-feedback"} key={idx}>{x}</div>)
                                            })
                                            }
                                        </div>
                                        <button type="submit" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                            Register
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
