import React, {Component} from "react";
import InputNumber from "../shared/InputNumber";
import AsyncAction from "../shared/AsyncAction";
//import {cartAddItem, cartAddItemRow} from "../../store/cart";
import {cartAddItemRow} from '../../store/cart/cartActions';
import classNames from "classnames";
import {connect} from 'react-redux';

// application

class ProductVariantBuyCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quantity: 1,
            showDiscounts: false,
            discount_price_per_pack: null,
            discount_price_per_unit: null,
            desktop: true
        };


        var that = this;
        setTimeout(function () {
            that.calcDiscount(that.state.quantity);
        }, 100);
    }

    calcDiscount(quantity) {
        var that = this;
        var bestDiscountFound = null;

        //make sure sorted highest to lowest, as we want to test the best discount possible for exiting
        this.props.variant.calculated_discounts.sort((a, b) => a.discount.items_required_to_apply_discount - b.discount.items_required_to_apply_discount);
        var foundDiscount = false;
        this.props.variant.calculated_discounts.some(function (productVariantDiscount) {
            if (productVariantDiscount.discount.items_required_to_apply_discount <= quantity) {
                that.setState({
                    "discount_price_per_pack": productVariantDiscount.discount_price_per_pack,
                    "discount_price_per_unit": productVariantDiscount.discount_price_per_unit
                });
                foundDiscount = true;
            }
        })
        if (!foundDiscount) {
            that.setState({
                "discount_price_per_pack": this.props.variant.current_price_per_pack,
                "discount_price_per_unit": this.props.variant.current_price_per_unit
            });
        }

        //

        // var thing = Math.max.apply(Math, this.props.variant.calculated_discounts.map(function (o) {
        //     return o.discount.items_required_to_apply_discount;
        // }))
        //
        // this.props.variant.calculated_discounts.forEach(function (productVariantDiscount) {
        //
        //     if (quantity >= productVariantDiscount.discount.items_required_to_apply_discount) {
        //         that.setState({
        //             "discount_price_per_pack": productVariantDiscount.discount_price_per_pack,
        //             "discount_price_per_unit": productVariantDiscount.discount_price_per_unit
        //         });
        //     }
        //
        // });
    }

    handleChangeQuantity = (quantity) => {

        this.calcDiscount(quantity);
        this.setState({quantity});
    };

    toggleDiscount() {
        var that = this;
        this.setState({"showDiscounts": !that.state.showDiscounts});
    }

    render() {

        const {
            cartAddItemRow,
        } = this.props;


        return (
            (() => {
                if (this.props.desktop) {
                    return (
                        <tr key={this.props.index} className="spec__section">
                            <td>{this.props.variant.name}</td>
                            <td>{this.props.variant.units_per_pack} {this.props.variant.unit} {this.props.variant.pack}</td>
                            <td>{this.props.variant.lead_time} Days</td>
                            <td style={{position: "relative"}}>
                                £{this.props.variant.lowest_price_per_pack}<br/>
                                £{this.props.variant.lowest_price_per_unit}/{this.props.variant.unit.toLowerCase()}
                            </td>
                            <td>
                                <i className="fa fa-info-circle" aria-hidden="true"
                                   style={{padding: "10px", cursor: "pointer"}}
                                   onClick={this.toggleDiscount.bind(this)}></i>

                                {this.state.showDiscounts &&
                                    <div className="discount-popover-container">
                                        <div className="header">
                                            Available Discounts
                                            <i className="fa fa-times closeButton" aria-hidden="true"
                                               style={{padding: "10px", cursor: "pointer"}}
                                               onClick={this.toggleDiscount.bind(this)}></i>
                                        </div>
                                        <table style={{width: "100%"}}>
                                            <tr>
                                                <th>Purchase QTY</th>
                                                <th>Price</th>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <b>1-2</b>
                                                </td>
                                                <td>
                                                    <div className="priceContainer">
                                                        £{this.props.variant.current_price_per_pack}<br/>
                                                        £{this.props.variant.current_price_per_unit}/{this.props.variant.unit.toLowerCase()}
                                                    </div>
                                                </td>
                                            </tr>
                                            {this.props.variant.calculated_discounts.map((row, i) =>
                                                <tr key={i}>
                                                    <td>
                                                        <b>{row.discount.items_required_to_apply_discount}</b>
                                                    </td>
                                                    <td>
                                                        <div className="priceContainer">
                                                            £{row.discount_price_per_pack}<br/>
                                                            £{row.discount_price_per_unit}/{this.props.variant.unit.toLowerCase()}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </table>
                                    </div>
                                }

                            </td>
                            <td>

                                <div className="product__actions-item">
                                    <InputNumber
                                        id="product-quantity"
                                        aria-label="Quantity"
                                        className="product__quantity"
                                        size="lg"
                                        min={1}
                                        value={this.state.quantity}
                                        onChange={this.handleChangeQuantity}
                                    />
                                </div>
                            </td>

                            <td>
                                £{this.state.discount_price_per_pack}<br/>
                                £{this.state.discount_price_per_unit}/{this.props.variant.unit.toLowerCase()}
                            </td>

                            <td>
                                <div className="product__actions-item product__actions-item--addtocart">
                                    <AsyncAction
                                        action={() => cartAddItemRow(this.props.product, this.props.variant, [], this.state.quantity)}
                                        render={({run, loading}) => (
                                            <button
                                                type="button"
                                                onClick={run}
                                                disabled={!this.state.quantity}
                                                className={classNames('btn btn-primary btn-lg', {
                                                    'btn-loading': loading,
                                                })}>
                                                Add to cart 4
                                            </button>
                                        )}
                                    />
                                </div>
                            </td>
                        </tr>
                    )
                } else {
                    return (
                        <div key={this.props.index} className="card product-variant-buy-card">

                            <div className="card-body">
                                <h5 className="card-title">{this.props.variant.name}</h5>
                                {/*<p className="tableDetail">{this.props.variant.units_per_pack} {this.props.variant.unit} {this.props.variant.pack}</p>*/}
                            </div>

                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <b>Pack</b> - {this.props.variant.units_per_pack} {this.props.variant.unit} {this.props.variant.pack}
                                </li>
                                <li className="list-group-item"><b>DELIVERY TIME</b> - <span
                                    className="tableDetail"> {this.props.variant.lead_time} Days </span>
                                </li>
                                <li className="list-group-item"><b>Prices from</b> -
                                    <span className="tableDetail"> £{this.props.variant.lowest_price_per_pack} -
                                    £{this.props.variant.lowest_price_per_unit}/{this.props.variant.unit.toLowerCase()}</span>
                                    <div style={{display: "inline-block"}}>
                                        <i className="fa fa-info-circle" aria-hidden="true"
                                           style={{padding: "10px", cursor: "pointer"}}
                                           onClick={this.toggleDiscount.bind(this)}></i>

                                        {this.state.showDiscounts &&
                                            <div className="discount-popover-container">
                                                <div className="header">
                                                    Available Discounts
                                                    <i className="fa fa-times closeButton" aria-hidden="true"
                                                       style={{padding: "10px", cursor: "pointer"}}
                                                       onClick={this.toggleDiscount.bind(this)}></i>
                                                </div>
                                                <table style={{width: "100%"}}>
                                                    <tr>
                                                        <th>Purchase QTY</th>
                                                        <th>Price</th>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <b>1-2</b>
                                                        </td>
                                                        <td>
                                                            <div className="priceContainer">
                                                                £{this.props.variant.current_price_per_pack}<br/>
                                                                £{this.props.variant.current_price_per_unit}/{this.props.variant.unit.toLowerCase()}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {this.props.variant.calculated_discounts.map((row, i) =>
                                                        <tr key={i}>
                                                            <td>
                                                                <b>{row.discount.items_required_to_apply_discount}</b>
                                                            </td>
                                                            <td>
                                                                <div className="priceContainer">
                                                                    £{row.discount_price_per_pack}<br/>
                                                                    £{row.discount_price_per_unit}/{this.props.variant.unit.toLowerCase()}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </table>
                                            </div>
                                        }
                                    </div>
                                </li>

                                <li className="list-group-item">
                                    <div className="tablePrice"> £{this.state.discount_price_per_pack}<br/>
                                        <span
                                            className="tablePriceUnit">£{this.state.discount_price_per_unit}/{this.props.variant.unit.toLowerCase()}</span>
                                    </div>
                                </li>
                                <li className="list-group-item text-center">
                                    <div className="product__actions-item">
                                        <InputNumber
                                            id="product-quantity"
                                            aria-label="Quantity"
                                            className="product__quantity"
                                            size="lg"
                                            min={1}
                                            value={this.state.quantity}
                                            onChange={this.handleChangeQuantity}
                                        />
                                    </div>

                                    <div>
                                        <div
                                            className="product__actions-item product__actions-item--addtocart card-add-to-cart">
                                            <AsyncAction
                                                action={() => cartAddItemRow(this.props.product, this.props.variant, [], this.state.quantity)}
                                                render={({run, loading}) => (
                                                    <button
                                                        type="button"
                                                        onClick={run}
                                                        disabled={!this.state.quantity}
                                                        className={classNames('btn btn-primary btn-lg', {
                                                            'btn-loading': loading,
                                                        })}>
                                                        Add to cart
                                                    </button>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </li>
                            </ul>


                        </div>
                    )
                }
            })()
        )
    }
}

const mapDispatchToProps = {
    cartAddItemRow
};

//export default connect()(ProductVariantBuyRow);

export default connect(
    () => ({}),
    mapDispatchToProps,
)(ProductVariantBuyCard);


//export default ProductVariantBuyRow;
